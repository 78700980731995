import React, { useContext, useState, useEffect } from 'react';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
} from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
} from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

import {FaWindowClose} from 'react-icons/fa';
import {BsFillArrowRightCircleFill} from 'react-icons/bs';

const auth = getAuth(app);

const db = getFirestore(app);

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;




const Bots = () => {
    const [olive, setOlive] = useState([]);
    const [joy, setJoy] = useState([]);
    const [jackie, setJackie] = useState([]);
    const [eve, setEve] = useState([]);
    const [kerry, setKerry] = useState([]);
    const [ben, setBen] = useState([]);

    const [OlivePostModal, setOlivePostModal] = useState(false);
    const [JoyPostModal, setJoyPostModal] = useState(false);
    const [JackiePostModal, setJackiePostModal] = useState(false);
    const [EvePostModal, setEvePostModal] = useState(false);
    const [KerryPostModal, setKerryPostModal] = useState(false);
    const [BenPostModal, setBenPostModal] = useState(false);

    const [oliveIndex, setOliveIndex] = useState(0);
    const [joyIndex, setJoyIndex] = useState(0);
    const [jackieIndex, setJackieIndex] = useState(0);
    const [eveIndex, setEveIndex] = useState(0);
    const [kerryIndex, setKerryIndex] = useState(0);
    const [benIndex, setBenIndex] = useState(0);

    const handleOliveIndex = () =>{
        if (oliveIndex > 4){
            toggleOliveModal();
        }
        setOliveIndex(oliveIndex+1);
        
      }

    const handleJoyIndex = () =>{
        if (joyIndex > 4){
            toggleJoyModal();
        }
        setJoyIndex(joyIndex+1);
      }

    const handleJackieIndex = () =>{
        if (jackieIndex > 4){
            toggleJackieModal();
        }
        setJackieIndex(jackieIndex+1);
      }

    const handleEveIndex = () =>{
        if (eveIndex > 4){
            toggleEveModal();
        }
        setEveIndex(eveIndex+1);
      }

    const handleKerryIndex = () =>{
        if (kerryIndex > 4){
            toggleKerryModal();
        }
        setKerryIndex(kerryIndex+1);
      }

    const handleBenIndex = () =>{
        if (benIndex > 4){
            toggleBenModal();
        }
        setBenIndex(benIndex+1);
      }

    const toggleOliveModal = () => {
        setOlivePostModal(!OlivePostModal);
    };

    const toggleJoyModal = () => {
        setJoyPostModal(!JoyPostModal);
    };

    const toggleJackieModal = () => {
        setJackiePostModal(!JackiePostModal);
    };

    const toggleEveModal = () => {
        setEvePostModal(!EvePostModal);
    };

    const toggleKerryModal = () => {
        setKerryPostModal(!KerryPostModal);
    };

    const toggleBenModal = () => {
        setBenPostModal(!BenPostModal);
    };

    if(OlivePostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(JoyPostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(JackiePostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(EvePostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(KerryPostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }
    if(BenPostModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setOlive(doc.data().olive_morning)
            setJoy(doc.data().joy_morning)
            setJackie(doc.data().jackie_morning)
            setEve(doc.data().eve_morning)
            setKerry(doc.data().kerry_morning)
            setBen(doc.data().ben_morning)

        });


    }, []);
    
    return(
        <div>
            <div className="bots">
                <div className="botIndi">
                    <img src={OliveStory} onClick={toggleOliveModal} alt="olive" />
                    <p>올리브</p>
                    <div className="botnoti">{olive.length}</div>
                </div>
                <div className="botIndi">
                    <img src={JoyStory} onClick={toggleJoyModal} alt="joy" />
                    <p>조이</p>
                    <div className="botnoti">{joy.length}</div>
                </div>

                <div className="botIndi">
                    <img src={JackieStory} onClick={toggleJackieModal} alt="jackie" />
                    <p>재키</p>
                    <div className="botnoti">{jackie.length}</div>
                </div>
          
                <div className="botIndi">
                    <img src={EveStory} onClick={toggleEveModal} alt="eve" />
                    <p>이브</p>
                    <div className="botnoti">{eve.length}</div>
                </div>
                <div className="botIndi">
                    <img src={KerryStory} onClick={toggleKerryModal} alt="kerry" />
                    <p>커리</p>
                    <div className="botnoti">{kerry.length}</div>
                </div>
                <div className="botIndi">
                    <img src={BenStory} onClick={toggleBenModal} alt="ben" />
                    <p>벤</p>
                    <div className="botnoti">{ben.length}</div>
                </div>
         
            </div>

            

            

            


            {OlivePostModal && (
                    <div className="modal">
                        <div onClick={toggleOliveModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>올리브</h2>
                            <img src={OliveStory} alt="olive" />
                            
                            <p>
                            {olive[oliveIndex].content}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleOliveModal}/>
                            <BsFillArrowRightCircleFill onClick={handleOliveIndex}/>
                        </div>
                        
                    </div>
            )}

            {JoyPostModal && (
                    <div className="modal">
                        <div onClick={toggleJoyModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>조이</h2>
                            <img src={JoyStory} alt="olive" />
                            <p>
                            {joy[joyIndex].content}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleJoyModal}/>
                            <BsFillArrowRightCircleFill onClick={handleJoyIndex}/>
                        </div>
                        
                    </div>
            )}

{JackiePostModal && (
                    <div className="modal">
                        <div onClick={toggleJackieModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>재키</h2>
                            <img src={JackieStory} alt="olive" />
                            
                            <p>
                            {jackie[jackieIndex].content}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleJackieModal}/>
                            <BsFillArrowRightCircleFill onClick={handleJackieIndex}/>
                        </div>
                    </div>
            )}

{EvePostModal && (
                    <div className="modal">
                        <div onClick={toggleEveModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>이브</h2>
                            <img src={EveStory} alt="olive" />
                            
                            <p>
                            {eve[eveIndex].content}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleEveModal}/>
                            <BsFillArrowRightCircleFill onClick={handleEveIndex}/>
                        </div>
                    </div>
            )}

{KerryPostModal && (
                    <div className="modal">
                        <div onClick={toggleKerryModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>커리</h2>
                            <img src={KerryStory} alt="olive" />
                            
                            <p>
                            {kerry[kerryIndex].content}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleKerryModal}/>
                            <BsFillArrowRightCircleFill onClick={handleKerryIndex}/>
                        </div>
                    </div>
            )}

{BenPostModal && (
                    <div className="modal">
                        <div onClick={toggleBenModal} className="overlay"></div>
                        <div className="modal-content">
                            <div className="bars">
                                <div className="barActive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                                <div className="barInactive"></div>
                            </div>
                            <h2>벤</h2>
                            <img src={BenStory} alt="olive" />
                            
                            <p>
                            {ben[benIndex].content}
                            </p>
                            
                            <FaWindowClose className="close-modal" onClick={toggleBenModal}/>
                            <BsFillArrowRightCircleFill onClick={handleBenIndex}/>
                        </div>
                        
                    </div>
            )}
        </div>
)
}
 
export default Bots
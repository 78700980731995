import React, {useContext, useState, useEffect} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import {CgProfile} from 'react-icons/cg';
import {AiFillQuestionCircle} from 'react-icons/ai';
import {FaWindowClose} from 'react-icons/fa';

const auth = getAuth(app);

const db = getFirestore(app);  



export default function BillingProfile() {
  const navigate = useNavigate()
  
  const handleLogout = ()=> {
    signOut(auth)
        .then(() => {
        console.log('user signed out')
        navigate('/signin')
        console.log(auth.currentUser);
        })
        .catch(err => {
        console.log(err.message)
        })
}
  return (
    <div className="containerBasic">
      <div className="profilenav">
        <nav>
          <ul>
            <li onClick={() => navigate('/profile')}>프로필</li>
            <li onClick={() => navigate('/billingprofile')}>청구 정보</li>
            <li onClick={()=>handleLogout()}>로그아웃</li>
          </ul>
        </nav>
      </div>
        <div>
          <p>청구 정보</p>
        </div>
        <div>
          <div className="iii parallel">
            <h2>서비스 등급</h2>
            <p>유니버스 클래식</p>
          </div>
          <div className="iii parallel">
            <h2>결제 방법</h2>
            <p>신용 카드</p>
          </div>
          <div className="iii parallel">
            <h2>청구 주기</h2>
            <p>매월</p>
          </div>
          <div className="iii parallel">
            <h2>결제 내역</h2>
            <p>다음 결제일 2023년 11월 20일에 18000원 청구</p>
          </div>
        </div>

        <div className="cancel">
          <p>universe 구독 취소</p>
        </div>

        <div>
          <div>
            <p>개인정보처리방침</p>
          </div>
          <p>이용 약관</p>
          <p>환불 정책</p>
        </div>
    </div>
  )
}

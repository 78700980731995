import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useParams, useNavigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;


export default function DailyLog() {
    const [timeInterval, setTimeInterval] = useState("밤");
    return (
        <div className="container2">
            <p>Daily Log</p>
            <p>어서오세요! 오늘 {timeInterval}의 일지를 작성해보세요.</p>

            <div className='alla'>
                <div className='smallButton1'>간략히</div>
                <div className='smallButton1'>보통</div>
                <div className='smallButton1'>자세히</div>
            </div>

            <br/>
            <div className='alla'>
                <div className='smallButton1'>아침</div>
                <div className='smallButton1'>afternoon</div>
                <div className='smallButton1'>evening</div>
                <div className='smallButton1'>night</div>
                <div className='smallButton1'>새벽</div>
            </div>
        </div>
    )
}

import React, {useContext, useState, useEffect} from 'react';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;



const Narration = () => {
    const [narration, setNarration] = useState("");
    
    const handleClick = () => {
      alert('하트력이 +1 충전되었어요!')
      //이미 눌렀으면 충전 안됨. 새로운 내레이션에 대해서만.
    }
    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setNarration(doc.data().narration)
        });
      }, []);
    return(
        <div className="prompts" onClick={handleClick}>
            {narration? <p>{narration}</p> : null}
        </div>
)
}
 
export default Narration
import {initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyCoaMz-LO05GepHmqM2ZWqHSQ4MGvxXpEM",
  authDomain: "olive-82a1f.firebaseapp.com",
  databaseURL: "https://olive-82a1f.firebaseio.com",
  projectId: "olive-82a1f",
  storageBucket: "olive-82a1f.appspot.com",
  messagingSenderId: "465628404",
  appId: "1:465628404:web:759b35647af3e1b4bf82a1",
  measurementId: "G-CQFDPLNCNY"
};


const app = initializeApp(firebaseConfig);

export {app};
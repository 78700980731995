import React, {useContext, useState, useEffect} from 'react';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
} from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
} from 'firebase/auth'

import { app } from '../config/fbConfig';

import Brain from "../assets/images/brain2.png"

import {FaWindowClose} from 'react-icons/fa';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;

export default function Templates() {
    const [templates, setTemplates] = useState([]);

    const [templateShorts, setTemplateShorts] = useState([]);
    const [template1Short, setTemplate1Short] = useState("");
    const [template2Short, setTemplate2Short] = useState("");
    const [template3Short, setTemplate3Short] = useState("");
    const [template4Short, setTemplate4Short] = useState("");
    const [template5Short, setTemplate5Short] = useState("");
    const [template6Short, setTemplate6Short] = useState("");
    const [template7Short, setTemplate7Short] = useState("");
    const [template8Short, setTemplate8Short] = useState("");

    const [templateModal, setTemplateModal] = useState(false);

    const [text, setText] = useState("");


    const days = ['일요일','월요일','화요일','수요일','목요일','금요일','토요일'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    //for posting the date of the journal written 
    const [day, setDay] = useState("");
    const [month, setMonth] = useState('');
    const [numMonth, setNumMonth] = useState("");
    const [year, setYear] = useState('');
    const [day_week, set_Day_week] =useState('');

    //user information
    const [vec, setVec] = useState([]);
    const [svec, setSvec] = useState([]);
    const [basicStatusVec, setBasicStatusVec] = useState([]);
    const [level, setLevel] = useState("");

    //작성한 일기 handle위해
    const [journalSituation, setJournalSituation] = useState("");
    const [journalEmotion, setJournalEmotion] = useState("");

    
    const toggleModal = () => {
      setTemplateModal(!templateModal);
    };

    if(templateModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    useEffect(() => {
        setDay(new Date().getDate());
        setNumMonth(new Date().getMonth()+1);
        setMonth(months[new Date().getMonth()]);
        setYear(new Date().getFullYear());
        set_Day_week(days[ new Date().getDay() ])

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setTemplates(doc.data().templates)
            console.log(doc.data().templates);
            doc.data().templates.map(item => templateShorts.push(item.short))
            setTemplate1Short(templateShorts[0]);
            setTemplate2Short(templateShorts[1]);
            setTemplate3Short(templateShorts[2]);
            setTemplate4Short(templateShorts[3]);
            setTemplate5Short(templateShorts[4]);
            setTemplate6Short(templateShorts[5]);
            setTemplate7Short(templateShorts[6]);
            setTemplate8Short(templateShorts[7]);
        });
        
      }, []);
  return (
    <div className="templates">
        <p>연구원들이 당신을 위해 준비한 오늘 저녁의 일기 템플릿들을 살펴보세요!</p>
            <div className="pp">
              <img src={Brain} alt="product" />

                {!templates? <div>잠시만 기다려주세요...</div> : null}
                {templates?
                <div>
                  <div className="temp1" onClick={toggleModal}>
                    {template1Short}
                  </div>
                  <div className="temp2" onClick={toggleModal}>
                    {template2Short}
                  </div>
                  <div className="temp3" onClick={toggleModal}>
                    {template3Short}
                  </div>
                  <div className="temp4" onClick={toggleModal}>
                    {template4Short}
                  </div>
                  <div className="temp5" onClick={toggleModal}>
                    {template5Short}
                  </div>
                  <div className="temp6" onClick={toggleModal}>
                    {template6Short}
                  </div>
                  <div className="temp7" onClick={toggleModal}>
                    {template7Short}
                  </div>
                  <div className="temp8" onClick={toggleModal}>
                    {template8Short}
                  </div>
                </div> : null}
            </div>



        {templateModal && (
                    <div className="modal">
                        <div onClick={toggleModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>{year}년 {numMonth}월 {day}일 {day_week}</h2>
                            <div className="smallButton1">오늘의 일기</div>

                            <form>
                                <textarea type="text" placeholder="오늘의 일기를 작성해보세요." value={text} onChange={(e)=>setText(e.target.value)} required/>
                                <input type="submit" value="일기 포스트" />
                            </form>
                            {/* <button className="close-modal" onClick={toggleModal}>
                            CLOSE
                            </button> */}
                            <FaWindowClose className="close-modal" onClick={toggleModal}/>
                        </div>
                    </div>
            )}
    </div>
  )
}

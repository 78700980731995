import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate, Navigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

const auth = getAuth(app);

const db = getFirestore(app);  



export default function SignIn() {
    const navigate = useNavigate()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    

    const handleLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                console.log(result)
            })
            .then(ref=>{
                setEmail("");
                setPassword("");
                navigate('/')
            })
            .catch((error) => {
                alert(error.message);
                console.log(error.message);
            })

    }

    if (auth.currentUser) return <Navigate replace to="/" />

    return (
        <div className="authContainer">
            <p>당신의 아지트에 돌아오신 것을 환영합니다!</p>
            <div className="formContainer">
                <form onSubmit={handleLogin}>
                    <input type="email" placeholder="이메일을 입력하세요..." value={email} onChange={(e)=>setEmail(e.target.value)} required/>
                    <input type="password" placeholder="비밀번호를 입력하세요..." value={password} onChange={(e)=>setPassword(e.target.value)} required/>
                    <input type="submit" value="로그인" />
                </form>
            </div>

            <div>아직 유니버스 계정이 없으신가요?</div>
            <a href="/register">회원가입</a>
            <div className="stretch"></div>
        </div>
    )
}

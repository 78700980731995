import React, {useContext, useState, useEffect} from 'react';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

  import Sticker2 from "../assets/images/sticker2.png";

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;

export default function Stickers() {
    const [stickers, setStickers] = useState("");

    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
        setStickers(doc.data().stickers)})
 
      }, []);
    
  return (
    //격자템플릿을 6개 정도 만들어놓고, 임의의 랜덤 넘버 뽑아서 넘버별 격자템플릿//
    <div className="stickers">
        <p>유니버스 연구원들이 궁금한 사항을 스티커로 물어보고 있어요!</p>
            <img src={Sticker2} alt="product" /> 
            
        <div className="stickerGridContainer">
          <div className="sticker1">
            <img src={Sticker2} alt="product" />
          </div> 

          <div className="sticker2">
            <img src={Sticker2} alt="product" />
          </div> 

          <div className="sticker3">
            <img src={Sticker2} alt="product" />
          </div> 

          <div className="sticker4">
            <img src={Sticker2} alt="product" />
          </div> 

          <div className="sticker5">
            <img src={Sticker2} alt="product" />
          </div> 

          <div className="sticker6">
            <img src={Sticker2} alt="product" />
          </div> 
        </div>

        <ul>
        { stickers && stickers.map(item =>{
            return <li>
            <span>{item}</span>
            </li>
        })}
        </ul>

        <br/>
        <p>[고정 스티커]

1. 당신의 직업은 무엇인가요? 

2. 00님은 가족 관계가 어떻게 되나요? 그리고 지금은 누구와 거주하고 있나요?

3. 좋아하거나 자주가는 식당이 있나요? 그곳에서 좋아하는 메뉴는요?

4. 자주 먹는 음식 메뉴가 무엇인가요?

5. 00님은 기분이 좋아지고 싶을 때 듣는 특별한 노래가 있나요? 

6. 00님은 편의점에서 주로 어떤 것들을 사나요?

7. 00님은 혼자서 잘 만들 수 있는 음식이 있나요?

8. 00님이 가장 좋아하는 책을 알ㄹ주세요. 

9. 어떤 날씨를 가장 좋아하나요?

10. 좋아하는 아이스크림 맛은?

11. 00님은 연애 상태가 어떻게 되시나요? (가능한 답으로는 결혼, 연애중, 싱글 등이 있습니다.)

12. 00님은 애완동물이 있나요? 있다면 종류는? 이름은 무엇인가요?

13. 00님은 아침형 인간인가요, 올빼미형 인간인가요? 

14. 00님이 가장 좋아하는 색깔은?

15. 00님이 가장 좋아하는 과자나 간식은?

16. 가장 좋아하는 과일
17. 어떤 취미를 가지고 있나요?(여러 개 입력 가능)
18. 당신이 가장 좋아하는 계절은?
19. 가장 좋아하는 노래는?
20. 요즘 가장 많이 듣는 노래는?
21. 인상적이었던 영화 음악이 있나요?
22. 당신의 버킷리스트에는 무엇이 있나요?
23. 언젠가 꼭 가보고 싶은 곳의 버킷리스트(여행 가고 싶은 나라/도시)
24. 가장 좋아하는 영화들은?
25. 의미있었던 공연이나 전시가 있나요? 생각나는대로 적어보세요.
26. 좋아하는 친구(들)는?
27. 가장 좋아하는 음료수는?
28. 가장 살고 싶은 국가/지역/도시는?
29. 당신이 사는 지역은?(거주하는 곳)
30. 싫어하는 음식이 있나요?
31. 나만의 독특한 습관이 있다면 뭐든지 알려주세요.
32. 힘들 때 위로가 되어준 음악이 있다면?
33. 당신의 좌우명은?
34. 지금까지 가장 많이 본 영화는?/몇 번을 봐도 질리지 않는 영화가 있다면?
35. 당신이 좋아하는 노래 가사?
36. 요즘 가장 즐겨보는 티비프로는?(좋아하는 TV프로그램)
37. 좋아하는 작가는?
38. 좋아하는 미술 작품들은?
39. 좋아하는 게임에는?
40. 좋아하는 도시는?
41. 여행갔던 곳들은?
42. 가장 좋아하는 악기는?
43. 몇 번을 읽어도 질리지 않는 책이 있다면?
44. 요즘 즐겨보는 드라마가 있나요?
45. 좋아하는 풍경을 상상해 본다면?
46. 기억에 남는 영화 속 감동적인 장면은?
47. 내 기분을 좋아지게 하는 장소가 있다면?
48. 내가 가진 옷들 중 가장 많은 색깔은 무엇인가요?
49. 나에게 잘 어울리는 색상은?
50. 요즘 내가 가장 즐겨입는 옷은?
51. 시도하고 싶은 재미있는 활동이 있다면요?
52. 내 이상형과 가장 닮은 연예인은?
53. 출출할 때 나만의 야식 메뉴는?
54. 내가 좋아하는 만화속 캐릭터는?
55. 주로 잠에 다는 시간은?
56. 함께있으면 재미있는 사람(들)은?
57. 봐도 봐도 질리지 않는 영화가 있다면?
58. 가장 좋아하는 디저트는?
59. 가장 좋아하는 식사는?
60. 쉬는 시간에 하기 가장 좋아하는 것은?</p>
    </div>
  )
}


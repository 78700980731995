import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp, limit
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')
const colNotiRef = collection(db, 'notifications')

const user = auth.currentUser;



const SmallNotification = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [oneNotification, setOneNotification] = useState("");

    useEffect(() => {
      // queries
      const q = query(colNotiRef, where("uid", "==", auth.currentUser.uid), orderBy('createdAt', "desc"), limit(1))
  
      // realtime collection data
      onSnapshot(q, (snapshot) => {
      let notifications = []
      snapshot.docs.forEach(doc => {
        notifications.push({ ...doc.data(), id: doc.id })
      })
      console.log(notifications);
      setNotifications(notifications);
      setOneNotification(notifications[0].content)
      })
    }, []); 

    return(
        <div className="prompts" onClick={()=>navigate('/journals')}>
            {/* <p>이브가 새로운 스토리를 포스트 했어요!</p> */}
            <p>{oneNotification}</p>
        </div>
)
}
 
export default SmallNotification
import React, {useContext, useState, useEffect} from 'react';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

import Sticker1 from "../assets/images/sticker1.png";
import Sticker2 from "../assets/images/sticker2.png";

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;

export default function Reports() {
  const [reports, setReports] = useState("");

  useEffect(() => {

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setReports(doc.data().reports)
        });
      }, []);
  return (
        <div className="reports">
            <p>연구원들이 준비한 보고서를 살펴보세요.</p>
                <img src={Sticker1} alt="product" />
                    <ul>
                    { reports && reports.map(item =>{
                        return <li>
                        <span>{item}</span>
                        </li>
                    })}
                    </ul>

                    <div className="stickerGridContainer">
                      <div className="sticker1">
                        <img src={Sticker2} alt="product" />
                      </div> 

                      <div className="sticker2">
                        <img src={Sticker2} alt="product" />
                      </div> 

                      <div className="sticker3">
                        <img src={Sticker2} alt="product" />
                      </div> 

                      <div className="sticker4">
                        <img src={Sticker2} alt="product" />
                      </div> 

                      <div className="sticker5">
                        <img src={Sticker2} alt="product" />
                      </div> 

                      <div className="sticker6">
                        <img src={Sticker2} alt="product" />
                      </div> 
                    </div>
        </div>

  )
}






import React, {useContext, useState, useEffect} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import {CgProfile} from 'react-icons/cg';
import {AiFillQuestionCircle} from 'react-icons/ai';
import {FaWindowClose} from 'react-icons/fa';

import axios from 'axios';

const auth = getAuth(app);

const db = getFirestore(app);  

export default function Profile() {
    const { IMP } = window;
      //아임포트
      IMP.init("imp30108654");
    const navigate = useNavigate()
    const [name, setName] = useState("");
    const [level, setLevel] = useState("");

    //for 정기구독
    const [cardNumber, setCardNumber] = useState("");
    const [expiry, setExpiry] =useState("");
    const [birth, setBirth] = useState("");
    const [pwd2Digit, setPwd2Digit] = useState("");
    const [customer_uid, setCustomer_uid] = useState("gildong_0001_1234");

    //비지터, 클래식, 오리지널 설명 모달
    const [statusModal, setStatusModal] = useState(false);

    //구독 요청 모달
    const [billingModal, setBillingModal] = useState(false);

    //하트력 충전 모달 ( 인증결제 모달임 )
    const [heartChargeModal, setHeartChargeModal] = useState(false);

    const toggleModal = () => {
      setStatusModal(!statusModal);
    };

    const toggletoggle = () => {
      setStatusModal(!statusModal);
      setBillingModal(!billingModal)
    }

    const toggleHeartModal = () => {
      setHeartChargeModal(!heartChargeModal);
    }

    const toggleBillingModal = () => {
      setBillingModal(!billingModal)
    }

    if(statusModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    //for heart modal
    if(heartChargeModal) {
      document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    const handleLogout = ()=> {
        signOut(auth)
            .then(() => {
            console.log('user signed out')
            navigate('/signin')
            console.log(auth.currentUser);
            })
            .catch(err => {
            console.log(err.message)
            })
    }

    //merchant_uid 생성 함수 -> 예) ORD20180223-000011 같이 생성되게.

    //200하트력 구매
    const onClickNice200Pay = async() => {
      //이전에 prepare로 가격 확인하는 부분 구현해야됨. 
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "2000",
        name : "하트력 200 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          // 이전 단계에서 구현한 결제정보 사후 검증 API 
          console.log(rsp.imp_uid)

          //axios 앞에 await 붙이는 걸로 돼있지만 일단 안되니까 뻄..
            const res = axios({
              url: "https://server-kor-bec38320dbef.herokuapp.com/payments/complete",
              method: "post",
              headers: { "Content-Type": "application/json" }, 
              data: { imp_uid: rsp.imp_uid, merchant_uid: "..." },
                });
                switch (res.status) {
                  case "vbankIssued":
                    // 가상계좌 발급 시 로직
                    break;
                  case "success":
                    // 결제 성공 시 로직
                    console.log("두둥 결제 성공~!")
                    break;
                }
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //500하트력 구매
    const onClickNice500Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "5000",
        name : "하트력 500 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //1000하트력 구매
    const onClickNice1000Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "10000",
        name : "하트력 1000 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //2000하트력구매
    const onClickNice2000Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "19000",
        name : "하트력 2000 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        buyer_tel : "010-1234-5678",
        buyer_addr : "서울특별시 강남구 삼성동",
        buyer_postcode : "123-456",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //5000하트력 구매
    const onClickNice5000Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "48000",
        name : "하트력 5000 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        buyer_tel : "010-1234-5678",
        buyer_addr : "서울특별시 강남구 삼성동",
        buyer_postcode : "123-456",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    const onClickPaypalPay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "paypal_v2",
        pay_method : "card",
        amount : "18000",
        name : "클래식 정기구독",
        merchant_uid : "뭐써야될지모르겠음"
      });
    }

    const handleFormSubmit = (event) => {
      event.preventDefault();
      const { cardNumber, expiry, birth, pwd2Digit, customer_uid } = this.state;
      axios({
        url: "https://server-kor-bec38320dbef.herokuapp.com/subscription/issue-billing", 
        method: "post",
        data: {
          cardNumber,
          expiry,
          birth,
          pwd2Digit,
          customer_uid,
        }
      }).then(rsp => {
    
      });
    };

    useEffect(() => {
      const unsub2 = onSnapshot(doc(db, 'users', auth.currentUser.uid), (doc) => {
        setName(doc.data().name);
        setLevel(doc.data().level);
    });

      
    }, []);

  return (
    <div className="container3">
      <div className="profilenav">
        <nav>
          <ul>
            <li onClick={() => navigate('/profile')}>프로필</li>
            <li onClick={() => navigate('/billingprofile')}>청구 정보</li>
            <li onClick={()=>handleLogout()}>로그아웃</li>
          </ul>
        </nav>
      </div>

      <div>
        <CgProfile className="profileIcon"/>
        <p>{name}님</p>
      </div>
      <p>유니버스 레벨 : {level}</p>
      <div className='parallel'>
        <p>상태 : Visitor</p>
        <AiFillQuestionCircle className='ii' onClick={toggleModal}/>
      </div>
      <p>하트력 : 400hr</p>
      <br/>
      <button onClick={toggleHeartModal}>하트력 구매</button>
      <br/>
      <br/>
      <button onClick={() => navigate('/services')}>서비스 이용 안내</button>
   
      {statusModal && (
                    <div className="modal">
                        <div onClick={toggleModal} className="overlay"></div>
                        <div className="modal-content">
                          <div className='parallel'>
                            <div>
                              <h2>클래식</h2>
                              <ul>
                                <li>18,000원 / 한 달</li>
                                <li>하루 일기 6회</li>
                                <li>검색 가능 6회</li>
                                <li>주 기본 제공 하트력 1000hr</li>
                              </ul>
                              <div className='profilebutton' onClick ={toggletoggle}><p>업그레이드</p></div>
                            </div>
                            
                            <div>
                              <h2>오리지널</h2>
                              <ul>
                                <li>23,000원 / 한 달</li>
                                <li>하루 일기 10회</li>
                                <li>검색 가능 10회</li>
                                <li>주 기본 제공 하트력 2000hr</li>
                              </ul>
                              <div className='profilebutton' onClick ={toggletoggle}><p>업그레이드</p></div>
                            </div>
                          </div>
                            <FaWindowClose className="close-modal" onClick={toggleModal}/>
                        </div>
                    </div>
            )}

      {heartChargeModal && (
                  <div className="modal">
                  <div onClick={toggleHeartModal} className="overlay"></div>
                  <div className="modal-content">
                      <FaWindowClose className="close-modal" onClick={toggleHeartModal}/>
                      <div className="notee">
                        <p>구매하신 하트력은 구매 후 3개월 이내에 사용해야하며, 그 이후 소멸 됩니다.</p>
                        <p>구매하신 하트력은 취소 및 환불이 불가합니다.</p>
                      </div>
                      <div className='parallel' onClick={onClickNice200Pay}>
                        <p>200하트력 구매</p> 
                        <div className='profilebutton'><p>2000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice500Pay}>
                        <p>500하트력 구매</p> 
                        <div className='profilebutton'><p>5000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice1000Pay}>
                        <p>1000하트력 구매</p> 
                        <div className='profilebutton'><p>10000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice2000Pay}>
                        <p>2000하트력 구매</p> 
                        <div className='profilebutton'><p>19000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice5000Pay}>
                        <p>5000하트력 구매</p> 
                        <div className='profilebutton'><p>48000원</p></div>
                      </div>
                  </div>
              </div>
      )}      

            {billingModal && (
                  <div className="modal">
                    <div onClick={toggleBillingModal} className="overlay"></div>
                      <div className="modal-content">
                        <p>정기 구독할 카드 정보를 입력하세요</p>
                        <FaWindowClose className="close-modal" onClick={toggleBillingModal}/>
                        <form onSubmit={handleFormSubmit}>
                          <label>
                              카드 번호
                              <input type="text" name="cardNumber" value={cardNumber} onChange={(e)=>setCardNumber(e.target.value)} />
                          </label>
                          <label>
                              카드 유효기간
                              <input type="text" name="expiry" value={expiry} onChange={(e)=>setExpiry(e.target.value)} />
                          </label>
                          <label>
                              생년월일
                              <input type="text" name="birth" value={birth} onChange={(e)=>setBirth(e.target.value)} />
                          </label>
                          <label>
                              카드 비밀번호 앞 두자리
                              <input type="text" name="pwd2Digit" value={pwd2Digit} onChange={(e)=>setPwd2Digit(e.target.value)} />
                          </label>
                          <input type="submit" value="결제하기" />
                        </form>
                                </div>
                            </div>
                    )}   
    </div>
  )
}

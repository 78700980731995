import React, {useContext, useState, useEffect} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth
  } from 'firebase/auth'

import {FaWindowClose} from 'react-icons/fa';

import axios from 'axios';

const auth = getAuth(app);

const db = getFirestore(app);  



const Post = () => {
    const { IMP } = window;
      //아임포트
      IMP.init("imp30108654");
    const navigate = useNavigate()
    const [text, setText] = useState("");
    //그냥 코멘트 최대 6개
    const [comment1, setComment1] = useState([]);
    const [comment2, setComment2] = useState([]);
    const [comment3, setComment3] = useState([]);
    const [comment4, setComment4] = useState([]);
    const [comment5, setComment5] = useState([]);
    const [comment6, setComment6] = useState([]);

    


    const [dailyCount, setDailyCount] = useState(0);

    const days = ['일요일','월요일','화요일','수요일','목요일','금요일','토요일'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    //for posting the date of the journal written 
    const [day, setDay] = useState("");
    const [month, setMonth] = useState('');
    const [numMonth, setNumMonth] = useState("");
    const [year, setYear] = useState('');
    const [day_week, set_Day_week] =useState('');

    //user information
    const [vec, setVec] = useState([]);
    const [svec, setSvec] = useState([]);
    const [basicStatusVec, setBasicStatusVec] = useState([]);
    const [level, setLevel] = useState("");

    //작성한 일기 handle위해
    const [journalSituation, setJournalSituation] = useState("");
    const [journalEmotion, setJournalEmotion] = useState("");

    //for level check & update
    const [numJournals, setNumJournals] = useState(0);
    const [numTemplates, setNumTemplates] = useState(0);
    const [numBotAnswers, setNumBotAnswers] = useState(0);

    const [postModal, setPostModal] = useState(false);
    //하트력 충전 모달 ( 인증결제 모달임 )
    const [heartChargeModal, setHeartChargeModal] = useState(false);
    const [excessWarningModal, setExcessWarningModal] = useState(false);


    const handleExcess = () => {
        setPostModal(!postModal);
        setExcessWarningModal(!excessWarningModal)
        
    } 
    const handleExcess2 = () => {
        setExcessWarningModal(!excessWarningModal)
        setHeartChargeModal(!heartChargeModal);
    }

    const toggleModal = () => {
        setPostModal(!postModal);
    };

    const toggleExcessModal = () => {
        setExcessWarningModal(!excessWarningModal)
    }

    const toggleHeartModal = () => {
        setHeartChargeModal(!heartChargeModal);
    }

    if(postModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(excessWarningModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(heartChargeModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    //200하트력 구매
    const onClickNice200Pay = async() => {
        //이전에 prepare로 가격 확인하는 부분 구현해야됨. 
        //노마드코더 예시
        IMP.request_pay({
          pg : "nice_v2.iamport01m",
          pay_method : "card",
          amount : "2000",
          name : "하트력 200 구매",
          merchant_uid : "뭐써야될지모르겠음",
          buyer_email : "구매자 이메일",
          buyer_name : "구매자 이름",
          m_redirect_url: "어쩌고저쩌고"
        }, function(rsp){
          // callback
        //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
          if (rsp.success) {   
            // axios로 HTTP 요청
            // 이전 단계에서 구현한 결제정보 사후 검증 API 
            console.log(rsp.imp_uid)
  
            //axios 앞에 await 붙이는 걸로 돼있지만 일단 안되니까 뻄..
              const res = axios({
                url: "https://server-kor-bec38320dbef.herokuapp.com/payments/complete",
                method: "post",
                headers: { "Content-Type": "application/json" }, 
                data: { imp_uid: rsp.imp_uid, merchant_uid: "..." },
                  });
                  switch (res.status) {
                    case "vbankIssued":
                      // 가상계좌 발급 시 로직
                      break;
                    case "success":
                      // 결제 성공 시 로직
                      console.log("두둥 결제 성공~!")
                      break;
                  }
          } else {
            alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
          }
        });
      }
  
      //500하트력 구매
      const onClickNice500Pay = async() => {
        //노마드코더 예시
        IMP.request_pay({
          pg : "nice_v2.iamport01m",
          pay_method : "card",
          amount : "5000",
          name : "하트력 500 구매",
          merchant_uid : "뭐써야될지모르겠음",
          buyer_email : "구매자 이메일",
          buyer_name : "구매자 이름",
          m_redirect_url: "어쩌고저쩌고"
        }, function(rsp){
          // callback
        //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
          if (rsp.success) {   
            // axios로 HTTP 요청
            axios({
              url: "{서버의 결제 정보를 받는 endpoint}",
              method: "post",
              headers: { "Content-Type": "application/json" },
              data: {
                imp_uid: rsp.imp_uid,
                merchant_uid: rsp.merchant_uid
              }
            }).then((data) => {
              // 서버 결제 API 성공시 로직
  //             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
  // 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
  // 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                  
            })
          } else {
            alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
          }
        });
      }
  
      //1000하트력 구매
      const onClickNice1000Pay = async() => {
        //노마드코더 예시
        IMP.request_pay({
          pg : "nice_v2.iamport01m",
          pay_method : "card",
          amount : "10000",
          name : "하트력 1000 구매",
          merchant_uid : "뭐써야될지모르겠음",
          buyer_email : "구매자 이메일",
          buyer_name : "구매자 이름",
          m_redirect_url: "어쩌고저쩌고"
        }, function(rsp){
          // callback
        //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
          if (rsp.success) {   
            // axios로 HTTP 요청
            axios({
              url: "{서버의 결제 정보를 받는 endpoint}",
              method: "post",
              headers: { "Content-Type": "application/json" },
              data: {
                imp_uid: rsp.imp_uid,
                merchant_uid: rsp.merchant_uid
              }
            }).then((data) => {
              // 서버 결제 API 성공시 로직
  //             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
  // 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
  // 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                  
            })
          } else {
            alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
          }
        });
      }
  
      //2000하트력구매
      const onClickNice2000Pay = async() => {
        //노마드코더 예시
        IMP.request_pay({
          pg : "nice_v2.iamport01m",
          pay_method : "card",
          amount : "19000",
          name : "하트력 2000 구매",
          merchant_uid : "뭐써야될지모르겠음",
          buyer_email : "구매자 이메일",
          buyer_name : "구매자 이름",
          buyer_tel : "010-1234-5678",
          buyer_addr : "서울특별시 강남구 삼성동",
          buyer_postcode : "123-456",
          m_redirect_url: "어쩌고저쩌고"
        }, function(rsp){
          // callback
        //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
          if (rsp.success) {   
            // axios로 HTTP 요청
            axios({
              url: "{서버의 결제 정보를 받는 endpoint}",
              method: "post",
              headers: { "Content-Type": "application/json" },
              data: {
                imp_uid: rsp.imp_uid,
                merchant_uid: rsp.merchant_uid
              }
            }).then((data) => {
              // 서버 결제 API 성공시 로직
  //             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
  // 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
  // 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                  
            })
          } else {
            alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
          }
        });
      }
  
      //5000하트력 구매
      const onClickNice5000Pay = async() => {
        //노마드코더 예시
        IMP.request_pay({
          pg : "nice_v2.iamport01m",
          pay_method : "card",
          amount : "48000",
          name : "하트력 5000 구매",
          merchant_uid : "뭐써야될지모르겠음",
          buyer_email : "구매자 이메일",
          buyer_name : "구매자 이름",
          buyer_tel : "010-1234-5678",
          buyer_addr : "서울특별시 강남구 삼성동",
          buyer_postcode : "123-456",
          m_redirect_url: "어쩌고저쩌고"
        }, function(rsp){
          // callback
        //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
          if (rsp.success) {   
            // axios로 HTTP 요청
            axios({
              url: "{서버의 결제 정보를 받는 endpoint}",
              method: "post",
              headers: { "Content-Type": "application/json" },
              data: {
                imp_uid: rsp.imp_uid,
                merchant_uid: rsp.merchant_uid
              }
            }).then((data) => {
              // 서버 결제 API 성공시 로직
  //             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
  // 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
  // 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                  
            })
          } else {
            alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
          }
        });
      }

    const handleLevel = async() => {
        if (numJournals > 29 && numBotAnswers > 21 && numTemplates > 9){
            const docReff = doc(db, 'users', auth.currentUser.uid);
            updateDoc(docReff, {
                level : 6
            });
        }
        else if (numJournals > 21 && numBotAnswers > 17 && numTemplates > 5){
            const docReff = doc(db, 'users', auth.currentUser.uid);
            updateDoc(docReff, {
                level : 5
            });
        }
        else if (numJournals > 14 && numBotAnswers > 13 && numTemplates > 2){
            const docReff = doc(db, 'users', auth.currentUser.uid);
            updateDoc(docReff, {
                level : 4
            });
        }
        else if (numJournals > 9 && numBotAnswers > 9 && numTemplates > 0){
            const docReff = doc(db, 'users', auth.currentUser.uid);
            updateDoc(docReff, {
                level : 3
            });
        }
        else if (numJournals > 5 && numBotAnswers > 5 ){
            const docReff = doc(db, 'users', auth.currentUser.uid);
            updateDoc(docReff, {
                level : 2
            });
        }
        else if (numJournals > 2  && numBotAnswers > 2 ){
            const docReff = doc(db, 'users', auth.currentUser.uid);
            updateDoc(docReff, {
                level : 1
            });
        }
    }
    const addPost = async({text,comment1, comment2, comment3, comment4, comment5, comment6, situation, emotion, uid, createdAt, day, numMonth, month, year, day_week}) => {
        return new Promise((res, rej) => {
            addDoc(collection(db, "posts"), {
                    text, 
                    comment1, 
                    comment2, 
                    comment3, 
                    comment4, 
                    comment5, 
                    comment6,
                    situation,
                    emotion,
                    uid, 
                    createdAt,
                    day,
                    numMonth,
                    month, 
                    year, 
                    day_week
                })
                .then(ref => {
                    res(ref);
                })
                .catch(error => {
                    rej(error);
                });
        });
    };

    const handlePost = async(e) => {
        if (dailyCount > 5) {
            handleExcess();
        }
        else {
        e.preventDefault();
        if (level==0 || level ==1){
            await handleLowLevelPost()
        }
        else if(level==2 || level == 3|| level == 4){
            await handleMiddleLevelPost()
        }
        else if(level>4){
            await handleHighLevelPost()
        }
        else {
            await handleLowLevelPost();
        }

        console.log('prev:', numJournals)
        setNumJournals(numJournals + 1);
        console.log('after:', numJournals)
        await handleLevel();
        const docReff2 = doc(db, 'users', auth.currentUser.uid);
            updateDoc(docReff2, {
                numJournals : numJournals
            });
        }
    }

    const handleLowLevelPost = async() => {
        const docRef = doc(db, 'usersTexts', auth.currentUser.uid);
        updateDoc(docRef, {
            recentJournal : text
        });
        
        
        const res2 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/classify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data2 = await res2.json()


        const res3 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/emotionclassify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data3 = await res3.json()
        

            

                var temp_comm1 = [];
                const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data = await res.json()
        
                temp_comm1.push("올리브");
                temp_comm1.push(data.comment.content)
                setComment1(temp_comm1);

                //두번째 댓글
                var temp_comm2 = [];
                const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data4 = await res4.json()
        
                temp_comm2.push("벤");
                temp_comm2.push(data4.comment.content)
                setComment2(temp_comm2);

                //세번째 댓글
                var temp_comm3 = [];
                const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data5 = await res5.json()
        
                temp_comm3.push("조이");
                temp_comm3.push(data5.comment.content)
                setComment3(temp_comm3);

                //네번째 댓글
                var temp_comm4 = [];
                const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data6 = await res6.json()
        
                temp_comm4.push("재키");
                temp_comm4.push(data6.comment.content)
                setComment4(temp_comm4);
        
        
                //애드포스트

                addPost({text : text,  comment1 : temp_comm1, comment2 : temp_comm2, comment3 : temp_comm3, comment4 : temp_comm4, comment5 : comment5, comment6: comment6, situation : data2.classifyResult.content, emotion:data3.classifyResult.content, uid: auth.currentUser.uid, createdAt: serverTimestamp(), day : day, numMonth : numMonth, month : month, year : year, day_week : day_week})
                .then(ref => {
                    setText("");
                    toggleModal();
                    navigate('/');
                })
                .then(()=>console.log('post succededd'))
                .catch(error => {
                    alert(error);
                });
            const docRef2 = doc(db, 'usersInfos', auth.currentUser.uid);
            updateDoc(docRef2, {
                recentJournal : text,
                recentSituation : data2.classifyResult.content, 
                recentEmotion : data3.classifyResult.content
            });
    
            // Add a new document with a generated id.
            const docRef3 = await addDoc(collection(db, "notifications"), {
                uid: auth.currentUser.uid,
                content: "조이 외 3명의 연구원이 댓글을 남겼습니다.",
                createdAt: serverTimestamp()
            });
     
    }
    const handleMiddleLevelPost = async()=> {

        //array shuffle
        function shuffle(array) {
            let currentIndex = array.length,  randomIndex;
          
            // While there remain elements to shuffle.
            while (currentIndex > 0) {
          
              // Pick a remaining element.
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
          
            return array;
          }
          
        //crumpt 관련
        var temp_crumps = [];
        if (vec[0]==0 ||vec[2]==0 ||vec[4]==0){
            temp_crumps.push("0")
        }
        if (vec[0]==1 ||vec[2]==1 ||vec[4]==1){
            temp_crumps.push('1')
        }
        if (vec[0]==2 ||vec[2]==2 ||vec[4]==2){
            temp_crumps.push('2')
        }
        if (vec[0]==3 ||vec[2]==3 ||vec[4]==3){
            temp_crumps.push('3')
        }
        if (vec[0]==4 ||vec[2]==4 ||vec[4]==4){
            temp_crumps.push('4')
        }
        if (vec[0]==5 ||vec[2]==5 ||vec[4]==5){
            temp_crumps.push('5')
        }
        if (vec[0]==6 ||vec[2]==6 ||vec[4]==6){
            temp_crumps.push('6')
        }
        if (vec[0]==7 ||vec[2]==7 ||vec[4]==7){
            temp_crumps.push('7')
        }
        if (vec[0]==8 ||vec[2]==8 ||vec[4]==8){
            temp_crumps.push('8')
        }
        if (vec[0]==9 ||vec[2]==9 ||vec[4]==9){
            temp_crumps.push('9')
        }
        //지지
        if (vec[1]==0 ||vec[3]==0 ||vec[5]==0){
            temp_crumps.push('10')
        }
        if (vec[1]==1 ||vec[3]==1 ||vec[5]==1){
            temp_crumps.push('11')
        }
        if (vec[1]==2 ||vec[3]==2 ||vec[5]==2){
            temp_crumps.push('12')
        }
        if (vec[1]==3 ||vec[3]==3 ||vec[5]==3){
            temp_crumps.push('13')
        }
        if (vec[1]==4 ||vec[3]==4 ||vec[5]==4){
            temp_crumps.push('14')
        }
        if (vec[1]==5 ||vec[3]==5 ||vec[5]==5){
            temp_crumps.push('15')
        }
        if (vec[1]==6||vec[3]==6 ||vec[5]==6){
            temp_crumps.push('16')
        }
        if (vec[1]==7 ||vec[3]==7 ||vec[5]==7){
            temp_crumps.push('17')
        }
        if (vec[1]==8 ||vec[3]==8||vec[5]==8){
            temp_crumps.push('18')
        }
        if (vec[1]==9 ||vec[3]==9 ||vec[5]==9){
            temp_crumps.push('19')
        }
        if (vec[1]==10 ||vec[3]==10 ||vec[5]==10){
            temp_crumps.push('20')
        }
        if (vec[1]==11 ||vec[3]==11 ||vec[5]==11){
            temp_crumps.push('21')
        }

        shuffle(temp_crumps)
        if (temp_crumps.length < 6){
            temp_crumps.push('21')
            temp_crumps.push('20')
            temp_crumps.push('19')
        }

        const docRef = doc(db, 'usersTexts', auth.currentUser.uid);
        updateDoc(docRef, {
            recentJournal : text
        });
        
        

        const res2 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/classify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data2 = await res2.json()
        console.log(data2.classifyResult.content);


        const res3 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/emotionclassify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data3 = await res3.json()
        console.log(data3.classifyResult.content);
        

            
        for (let i = 0; i < temp_crumps.length; i++) {
                temp_crumps[i]= "a" + temp_crumps[i] + "c";
              }

                var temp_comm1 = [];
                const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data = await res.json()
        
                temp_comm1.push("올리브");
                temp_comm1.push(data.comment.content)
                console.log(data.comment.content);
                setComment1(temp_comm1);

                //두번째 댓글
                var temp_comm2 = [];
                const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data4 = await res4.json()
        
                temp_comm2.push("벤");
                temp_comm2.push(data4.comment.content)
                setComment2(temp_comm2);

                //세번째 댓글
                var temp_comm3 = [];
                const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data5 = await res5.json()
        
                temp_comm3.push("조이");
                temp_comm3.push(data5.comment.content)
                setComment3(temp_comm3);

                //네번째 댓글
                var temp_comm4 = [];
                const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[0]}`, {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data6 = await res6.json()
        
                temp_comm4.push("재키");
                temp_comm4.push(data6.comment.content)
                setComment4(temp_comm4);
        

                //다섯번째 댓글
                var temp_comm5 = [];
                const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[1]}`, {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data7 = await res7.json()
        
                temp_comm5.push("이브");
                temp_comm5.push(data7.comment.content)
                setComment5(temp_comm5);

                
                addPost({text : text,  comment1 : temp_comm1, comment2 : temp_comm2, comment3 : temp_comm3, comment4 : temp_comm4, comment5 : temp_comm5, comment6 : comment6, situation : data2.classifyResult.content, emotion:data3.classifyResult.content, uid: auth.currentUser.uid, createdAt: serverTimestamp(), day : day, numMonth : numMonth, month : month, year : year, day_week : day_week})
                .then(ref => {
                    setText("");
                    toggleModal();
                    navigate('/');
                })
                .then(()=>console.log('post succededd'))
                .catch(error => {
                    alert(error);
                });
            const docRef2 = doc(db, 'usersInfos', auth.currentUser.uid);
            updateDoc(docRef2, {
                recentJournal : text,
                recentSituation : data2.classifyResult.content, 
                recentEmotion : data3.classifyResult.content
            });
    
            // Add a new document with a generated id.
            const docRef3 = await addDoc(collection(db, "notifications"), {
                uid: auth.currentUser.uid,
                content: "조이 외 4명의 연구원이 댓글을 남겼습니다.",
                createdAt: serverTimestamp()
            });
        
    }
    const handleHighLevelPost = async() => {
        console.log(vec);

        //array shuffle
        function shuffle(array) {
            let currentIndex = array.length,  randomIndex;
          
            // While there remain elements to shuffle.
            while (currentIndex > 0) {
          
              // Pick a remaining element.
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
          
            return array;
          }
          
        //crumpt 관련
        var temp_crumps = [];
        if (vec[0]==0 ||vec[2]==0 ||vec[4]==0){
            temp_crumps.push("0")
        }
        if (vec[0]==1 ||vec[2]==1 ||vec[4]==1){
            temp_crumps.push('1')
        }
        if (vec[0]==2 ||vec[2]==2 ||vec[4]==2){
            temp_crumps.push('2')
        }
        if (vec[0]==3 ||vec[2]==3 ||vec[4]==3){
            temp_crumps.push('3')
        }
        if (vec[0]==4 ||vec[2]==4 ||vec[4]==4){
            temp_crumps.push('4')
        }
        if (vec[0]==5 ||vec[2]==5 ||vec[4]==5){
            temp_crumps.push('5')
        }
        if (vec[0]==6 ||vec[2]==6 ||vec[4]==6){
            temp_crumps.push('6')
        }
        if (vec[0]==7 ||vec[2]==7 ||vec[4]==7){
            temp_crumps.push('7')
        }
        if (vec[0]==8 ||vec[2]==8 ||vec[4]==8){
            temp_crumps.push('8')
        }
        if (vec[0]==9 ||vec[2]==9 ||vec[4]==9){
            temp_crumps.push('9')
        }
        //지지
        if (vec[1]==0 ||vec[3]==0 ||vec[5]==0){
            temp_crumps.push('10')
        }
        if (vec[1]==1 ||vec[3]==1 ||vec[5]==1){
            temp_crumps.push('11')
        }
        if (vec[1]==2 ||vec[3]==2 ||vec[5]==2){
            temp_crumps.push('12')
        }
        if (vec[1]==3 ||vec[3]==3 ||vec[5]==3){
            temp_crumps.push('13')
        }
        if (vec[1]==4 ||vec[3]==4 ||vec[5]==4){
            temp_crumps.push('14')
        }
        if (vec[1]==5 ||vec[3]==5 ||vec[5]==5){
            temp_crumps.push('15')
        }
        if (vec[1]==6||vec[3]==6 ||vec[5]==6){
            temp_crumps.push('16')
        }
        if (vec[1]==7 ||vec[3]==7 ||vec[5]==7){
            temp_crumps.push('17')
        }
        if (vec[1]==8 ||vec[3]==8||vec[5]==8){
            temp_crumps.push('18')
        }
        if (vec[1]==9 ||vec[3]==9 ||vec[5]==9){
            temp_crumps.push('19')
        }
        if (vec[1]==10 ||vec[3]==10 ||vec[5]==10){
            temp_crumps.push('20')
        }
        if (vec[1]==11 ||vec[3]==11 ||vec[5]==11){
            temp_crumps.push('21')
        }

        shuffle(temp_crumps)
        if (temp_crumps.length < 6){
            temp_crumps.push('21')
            temp_crumps.push('20')
            temp_crumps.push('19')
        }

        const docRef = doc(db, 'usersTexts', auth.currentUser.uid);
        updateDoc(docRef, {
            recentJournal : text
        });
        
        

        const res2 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/classify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data2 = await res2.json()
        console.log(data2.classifyResult.content);


        const res3 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/emotionclassify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({journal: text}),
            method: 'POST'
        })
        const data3 = await res3.json()
        console.log(data3.classifyResult.content);
        

        if (data2.classifyResult.content.includes("일상") ){
            
            for (let i = 0; i < temp_crumps.length; i++) {
                temp_crumps[i]= "a" + temp_crumps[i] + "c";
              }

                var temp_comm1 = [];
                const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data = await res.json()
        
                temp_comm1.push("올리브");
                temp_comm1.push(data.comment.content)
                console.log(data.comment.content);
                setComment1(temp_comm1);

                //두번째 댓글
                var temp_comm2 = [];
                const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[0]}`, {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data4 = await res4.json()
        
                temp_comm2.push("벤");
                temp_comm2.push(data4.comment.content)
                setComment2(temp_comm2);

                //세번째 댓글
                var temp_comm3 = [];
                const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[1]}`, {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data5 = await res5.json()
        
                temp_comm3.push("조이");
                temp_comm3.push(data5.comment.content)
                setComment3(temp_comm3);

                //네번째 댓글
                var temp_comm4 = [];
                const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[2]}`, {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data6 = await res6.json()
        
                temp_comm4.push("재키");
                temp_comm4.push(data6.comment.content)
                setComment4(temp_comm4);
        

                //다섯번째 댓글
                var temp_comm5 = [];
                const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[3]}`, {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data7 = await res7.json()
        
                temp_comm5.push("이브");
                temp_comm5.push(data7.comment.content)
                setComment5(temp_comm5);

                //여섯번째 댓글
                var temp_comm6 = [];
                const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[4]}`, {
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({journal: text}),
                    method: 'POST'
                })
                const data8 = await res8.json()
        
                temp_comm6.push("커리");
                temp_comm6.push(data8.comment.content)
                setComment5(temp_comm6);
            }
            else if (data2.classifyResult.content.includes("인간관계") ){

                for (let i = 0; i < temp_crumps.length; i++) {
                    temp_crumps[i]= "a" + temp_crumps[i] + "b";
                  }
    
                    var temp_comm1 = [];
                    const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data = await res.json()
            
                    temp_comm1.push("조이");
                    temp_comm1.push(data.comment.content)
                    console.log(data.comment.content);
                    setComment1(temp_comm1);
    
                    //두번째 댓글
                    var temp_comm2 = [];
                    const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[0]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data4 = await res4.json()
            
                    temp_comm2.push("재키");
                    temp_comm2.push(data4.comment.content)
                    setComment2(temp_comm2);
    
                    //세번째 댓글
                    var temp_comm3 = [];
                    const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[1]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data5 = await res5.json()
            
                    temp_comm3.push("이브");
                    temp_comm3.push(data5.comment.content)
                    setComment3(temp_comm3);
    
                    //네번째 댓글
                    var temp_comm4 = [];
                    const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[2]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data6 = await res6.json()
            
                    temp_comm4.push("커리");
                    temp_comm4.push(data6.comment.content)
                    setComment4(temp_comm4);
            
    
                    //다섯번째 댓글
                    var temp_comm5 = [];
                    const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[3]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data7 = await res7.json()
            
                    temp_comm5.push("올리브");
                    temp_comm5.push(data7.comment.content)
                    setComment5(temp_comm5);
    
                    //여섯번째 댓글
                    var temp_comm6 = [];
                    const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[4]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data8 = await res8.json()
            
                    temp_comm6.push("벤");
                    temp_comm6.push(data8.comment.content)
                    setComment5(temp_comm6);
            }
            else if (data2.classifyResult.content.includes("일") ){

                for (let i = 0; i < temp_crumps.length; i++) {
                    temp_crumps[i]= "a" + temp_crumps[i] + "a";
                  }
    
                    var temp_comm1 = [];
                    const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data = await res.json()
            
                    temp_comm1.push("이브");
                    temp_comm1.push(data.comment.content)
                    console.log(data.comment.content);
                    setComment1(temp_comm1);
    
                    //두번째 댓글
                    var temp_comm2 = [];
                    const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[0]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data4 = await res4.json()
            
                    temp_comm2.push("커리");
                    temp_comm2.push(data4.comment.content)
                    setComment2(temp_comm2);
    
                    //세번째 댓글
                    var temp_comm3 = [];
                    const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[1]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data5 = await res5.json()
            
                    temp_comm3.push("조이");
                    temp_comm3.push(data5.comment.content)
                    setComment3(temp_comm3);
    
                    //네번째 댓글
                    var temp_comm4 = [];
                    const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[2]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data6 = await res6.json()
            
                    temp_comm4.push("재키");
                    temp_comm4.push(data6.comment.content)
                    setComment4(temp_comm4);
            
    
                    //다섯번째 댓글
                    var temp_comm5 = [];
                    const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[3]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data7 = await res7.json()
            
                    temp_comm5.push("올리브");
                    temp_comm5.push(data7.comment.content)
                    setComment5(temp_comm5);
    
                    //여섯번째 댓글
                    var temp_comm6 = [];
                    const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment'+`${temp_crumps[4]}`, {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data8 = await res8.json()
            
                    temp_comm6.push("벤");
                    temp_comm6.push(data8.comment.content)
                    setComment5(temp_comm6);
            }
            else {
     
    
                    var temp_comm1 = [];
                    const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data = await res.json()
            
                    temp_comm1.push("이브");
                    temp_comm1.push(data.comment.content)
                    console.log(data.comment.content);
                    setComment1(temp_comm1);
    
                    //두번째 댓글
                    var temp_comm2 = [];
                    const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data4 = await res4.json()
            
                    temp_comm2.push("커리");
                    temp_comm2.push(data4.comment.content)
                    setComment2(temp_comm2);
    
                    //세번째 댓글
                    var temp_comm3 = [];
                    const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data5 = await res5.json()
            
                    temp_comm3.push("조이");
                    temp_comm3.push(data5.comment.content)
                    setComment3(temp_comm3);
    
                    //네번째 댓글
                    var temp_comm4 = [];
                    const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data6 = await res6.json()
            
                    temp_comm4.push("재키");
                    temp_comm4.push(data6.comment.content)
                    setComment4(temp_comm4);
            
    
                    //다섯번째 댓글
                    var temp_comm5 = [];
                    const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data7 = await res7.json()
            
                    temp_comm5.push("올리브");
                    temp_comm5.push(data7.comment.content)
                    setComment5(temp_comm5);
    
                    //여섯번째 댓글
                    var temp_comm6 = [];
                    const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/comment', {
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({journal: text}),
                        method: 'POST'
                    })
                    const data8 = await res8.json()
            
                    temp_comm6.push("벤");
                    temp_comm6.push(data8.comment.content)
                    setComment5(temp_comm6);
            }
    

        addPost({text : text,  comment1 : temp_comm1, comment2 : temp_comm2, comment3 : temp_comm3, comment4 : temp_comm4, comment5 : temp_comm5, comment6 : temp_comm6, situation : data2.classifyResult.content, emotion:data3.classifyResult.content, uid: auth.currentUser.uid, createdAt: serverTimestamp(), day : day, numMonth : numMonth, month : month, year : year, day_week : day_week})
            .then(ref => {
                setText("");
                toggleModal();
                navigate('/');
            })
            .then(()=>console.log('post succededd'))
            .catch(error => {
                alert(error);
            });
        const docRef2 = doc(db, 'usersInfos', auth.currentUser.uid);
        updateDoc(docRef2, {
            recentJournal : text,
            recentSituation : data2.classifyResult.content, 
            recentEmotion : data3.classifyResult.content
        });

        // Add a new document with a generated id.
        const docRef3 = await addDoc(collection(db, "notifications"), {
            uid: auth.currentUser.uid,
            content: "조이 외 5명의 연구원이 댓글을 남겼습니다.",
            createdAt: serverTimestamp()
        });
            }

    useEffect(() => {
        setDay(new Date().getDate());
        setNumMonth(new Date().getMonth()+1);
        setMonth(months[new Date().getMonth()]);
        setYear(new Date().getFullYear());
        set_Day_week(days[ new Date().getDay() ])
        const unsub = onSnapshot(doc(db, 'usersInfos', auth.currentUser.uid), (doc) => {
            setVec(doc.data().vec);
            console.log(vec)
            setSvec(doc.data().svec);
            setBasicStatusVec(doc.data().basicStatusVec);
        });
        const unsub2 = onSnapshot(doc(db, 'users', auth.currentUser.uid), (doc) => {
            setLevel(doc.data().level);
            setNumJournals(doc.data().numJournals);
            setNumTemplates(doc.data().numTemplates);
            setNumBotAnswers(doc.data().numBotAnswers);
            setDailyCount(doc.data().DailyNumCount);
        });

        

    }, []);

    return ( 
        <div>
            <div className="postTrigger" onClick={toggleModal}>
                <div className="categoryContainer">
                    <div className="smallButton1">오늘의 일기</div>
                    {/* <div className="smallButton1" onClick={()=>navigate('/moodcheckin')}>기분 체크인</div>
                    <div className="smallButton1" onClick={()=>navigate('/dailylog')}>일지</div> */}
                </div>

              <div className="holder">
                <p>오늘의 일기를 작성하세요!</p>
              </div>

              <div className="postTriggerFooter">
                <div className="smallButton">삭제</div>
                <div className="smallButton">게시</div>
              </div>
          </div>

          {postModal && (
                    <div className="modal">
                        <div onClick={toggleModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>{year}년 {numMonth}월 {day}일 {day_week}</h2>
                            <div className="smallButton1">오늘의 일기</div>

                            <form onSubmit={handlePost}>
                                <textarea type="text" placeholder="오늘의 일기를 작성해보세요." value={text} onChange={(e)=>setText(e.target.value)} required/>
                                {/* <input type="submit" value="일기 포스트" /> */}
                                <br/>
                                <button className="button" onClick={handlePost}>일기 포스트</button>
                            </form>
                            {/* <button className="close-modal" onClick={toggleModal}>
                            CLOSE
                            </button> */}
                            <FaWindowClose className="close-modal" onClick={toggleModal}/>
                        </div>
                    </div>
            )}

{excessWarningModal && (
                    <div className="modal">
                        <div onClick={toggleExcessModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>하루 사용 가능한 일기 게시 횟수를 초과하였습니다. 30 하트력을 사용하여 일기를 작성하시겠어요?</h2>
                            

                            
                            <button className="button" onClick={handleExcess2}>하트력 사용</button>
                            
                            {/* <button className="close-modal" onClick={toggleModal}>
                            CLOSE
                            </button> */}
                            <FaWindowClose className="close-modal" onClick={toggleExcessModal}/>
                        </div>
                    </div>
            )}

{heartChargeModal && (
                  <div className="modal">
                  <div onClick={toggleHeartModal} className="overlay"></div>
                  <div className="modal-content">
                      <FaWindowClose className="close-modal" onClick={toggleHeartModal}/>
                      <div className="notee">
                        <p>구매하신 하트력은 구매 후 3개월 이내에 사용해야하며, 그 이후 소멸 됩니다.</p>
                        <p>구매하신 하트력은 취소 및 환불이 불가합니다.</p>
                      </div>
                      <div className='parallel' onClick={onClickNice200Pay}>
                        <p>200하트력 구매</p> 
                        <div className='profilebutton'><p>2000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice500Pay}>
                        <p>500하트력 구매</p> 
                        <div className='profilebutton'><p>5000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice1000Pay}>
                        <p>1000하트력 구매</p> 
                        <div className='profilebutton'><p>10000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice2000Pay}>
                        <p>2000하트력 구매</p> 
                        <div className='profilebutton'><p>19000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice5000Pay}>
                        <p>5000하트력 구매</p> 
                        <div className='profilebutton'><p>48000원</p></div>
                      </div>
                  </div>
              </div>
      )}  
        </div>
     );
}
 
export default Post
import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

const auth = getAuth(app);

const db = getFirestore(app);  


//질문 4 1개 
export default function Register6() {
    const navigate = useNavigate()
    const [question4List, setQuestion4List] = useState([
      "온전히 나를 위해 하는 활동이 있나요?",
      "소질이나 재능과 관계없이 잘하고 싶은 일이 있나요?",
      "꼭 하고 싶었는데 하지 못한 일이 있었나요? 그 이유는 무엇인가요?",])
    
    const [numIndex, setNumIndex] = useState(0);

    const [text, setText] = useState("");
    return (
        <div className="authContainer">
            <p>{question4List[numIndex]}</p>
            <form onSubmit={console.log('**')}>
                <input type="text" placeholder="답변을 입력하세요." value={text} onChange={(e)=>setText(e.target.value)} required/>
                <input type="submit" value="제출" />
            </form>

            <div>이미 유니버스 계정이 있으신가요?</div>
            <a href="/signin">로그인</a>
            <div className="stretch"></div>
        </div>
    )
}

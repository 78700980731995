import { Route, Routes, useNavigate } from "react-router-dom"


export default function Services() {
  const navigate = useNavigate()
  return (
    <div className="content">
      <p>제4장 유료서비스 이용 및 결제
제18조 [유료서비스와 유료서비스 이용 회원]
•	① ‘회사’가 ‘회원’의 ‘유료서비스’ 이용신청을 승낙한 때(일부 서비스의 경우, 이용 신청 접수 후 사전 공지한 지정된 일자)로부터 ‘유료서비스’는 개시됩니다. 다만, ‘회사’의 기술적 사유 등 기타 사정에 의하여 ‘유료서비스’를 개시할 수 없는 경우에는 제3조(약관의 효력 및 변경)의 방법에 따라 ‘회원’에게 사전 공지합니다.
•	② ‘회사’는 ‘회원’의 ‘유료서비스’ 이용신청이 있게 되는 경우, 해당 ‘회원’에게 이용신청의 내용을 통지합니다.
•	③ 전항의 통지를 받은 ‘회원’은 의사표시의 불일치 등이 있는 경우 ‘회사’에 정정 또는 수정을 요청하여야 하며, ‘회사’는 ‘회원’의 요청에 따라 처리하여야 합니다. 다만, ‘회원’이 이미 대금을 지불한 경우에는 이 약관의 유료결제 해지 등에 관한 규정에 따릅니다.
•	④ ‘회사’는 정기구독 ‘유료서비스’를 제공합니다. 이는 ‘회원’이 ‘유료서비스’를 이용하기 위해 미리 등록한 결제수단을 통하여 월/년 단위로 이용 요금이 자동으로 결제되고 이용기간이 자동 갱신되는 서비스를 칭합니다. ‘회사’의 사정, 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.
•	⑤ ‘회사’와 저작권의 권리를 보유하고 있는 개인, 단체, 법인(‘권리자 등’) 간의 계약의 체결 여부 및 개별 계약의 내용, ‘권리자 등’의 사후 요청에 따라 개별 ‘유료서비스’ 또는 ‘콘텐츠’ 파일의 이용이 제한되거나 서비스 가능 지역, ‘콘텐츠’ 파일의 속성 등 내용이 사전 또는 사후 변경될 수 있습니다.
•	⑥ ‘회사’는 ‘권리자 등’의 요청이 있게 되는 경우, 해당 요청에 따라 ‘유료서비스’를 지원하는 기기의 종류, 회원별 이용 가능 기기 대 수, 동시 접속자 수 등을 변경할 수 있습니다.
•	⑦ ‘회원’의 '유료서비스' 이용이 해지되는 경우, ‘회원’의 작성한 ‘게시물’은 해지 시점부터 12개월간 보존한 뒤 영구 삭제하며 복구는 불가합니다.
________________________________________
제19조 [유료서비스 이용 계약]
•	① 본 약관에 동의한 '회원'이 ‘유료서비스’에 대한 이용신청을 하고 '회사'가 이를 승낙함(유료서비스의 ‘구매/결제완료 등’의 표시가 회원에게 절차상 표시된 시점)으로써 ‘유료서비스’ 이용 계약은 성립합니다.
•	② '회사'는 ‘회원’의 ‘유료서비스’ 결제 전에 해당 ‘유료서비스’의 내용, 가격, 이용기간, 이용방법, ‘구독취소’ 및 ‘정기구독’ 해지 조건 및 그 방법, 환불에 관한 사항에 관하여 '회원'이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 구매 페이지를 통해 정보를 제공합니다. 
•	③ '회사'는 본 약관 제12조 [회원탈퇴 및 회원자격의 정지]에서 정하는 사유가 발생하는 경우, '유료서비스' 이용신청을 승낙하지 않거나 승낙을 유보할 수 있습니다.
•	④ '회원’은 ‘유료서비스’ 이용 신청 시 필요한 제반 사항을 기재할 경우 ‘회원’의 현재의 사실과 일치하는 정보를 기재하여야 하며, ‘회원’이 이용하고자 하는 ‘유료서비스’의 결제방법의 선택 및 선택한 결제방법에 필요한 결제정보를 정확히 ‘회사’에 제공하여야 합니다.
•	⑤ ‘회원'은 전항의 해당 사항이 변경될 경우 지체 없이 ‘회사’가 정한 절차에 따라 변경사항을 고지하고, ‘회사’는 지체없이 변경사항을 반영하여야 합니다.
•	⑥ '회사'는 '유료서비스'를 이용하는 '회원'의 거래금액에 대하여 내부정책 및 외부 결제업체(PG사, 은행사, 카드사 등), 기타 관련 법령의 변경에 따라 '회원' 당 월 누적 결제액, 결제한도 등의 거래한도를 정할 수 있으며, '회원'은 '회사'가 정한 거래한도를 초과하는 범위의 '유료서비스'를 이용하고자 할 경우 거래한도의 초과로 인하여 '유료서비스'의 추가 이용이 불가능할 수 있습니다.
•	⑦ ‘회원’이 ‘회사’로부터 제공받아 보유하고 있는 이벤트성 ‘하트력’의 경우는 현금으로 환급되지 않으며, '회원'이 회원 가입 계약을 해지하거나 '회원'자격에 제한을 받을 경우 및 제공받은 날로부터 30일이 경과하면 자동으로 소멸됩니다.
________________________________________
제20조 [유료서비스의 변경]
•	① '회사’는 운영상, 기술상의 필요에 따라 공급하는 ‘유료서비스’ 가액을 변경할 수 있으며, 제5조(약관의 효력 및 변경)의 방법에 따라 ‘유료서비스’ 이용 ‘회원’에게 사전공지 합니다.
•	② ‘회원’이 이미 구매/가입한 ‘유료서비스’는 ‘회사’가 정한 정책에 따라 변경 신청을 할 수 있으며, 즉시 변경 혹은 1개월 예약 변경을 할 수 있습니다.
•	③ ‘회원’이 전항의 즉시 변경을 선택한 경우, ‘회원’이 변경 요청한 서비스로 즉시 교체됩니다. 다만, ‘회사’는 ‘회원’의 기존 구매/가입한 서비스의 남은 기간과 변경 요청한 서비스의 가격을 비례 배분하며, 이용 기간을 조정하며 새로운 결제 주기를 적용합니다.
•	④ ‘회원’이 제2항의 예약 변경을 선택한 경우, ‘회원’이 변경 요청한 달의 이용기간이 만료되는 날까지 기존 구매/가입한 서비스를 이용할 수 있으며, 이용기간 만료일 다음날부터 변경 요청한 서비스로 변경 적용됩니다.
•	⑤ 애플 및 구글 IAP(In-App-Purchase)을 통해 결제되는 ‘유료서비스’는 애플 및 구글 각사의 정책에 따라 수수료를 감안하여 다른 가격으로 판매될 수 있습니다.________________________________________
제21조 [유료서비스 이용에 관한 해제∙해지]
•	① ‘유료서비스’는 관리메뉴, 고객센터 등을 통해 해지할 수 있습니다. ‘회원’이 ‘정기구독’을 해제∙해지했을 경우, 다음 이용기간부터 ‘유료서비스’에 대한 자동 결제가 이루어 지지 않습니다. 이미 ‘결제’가 이루어진 ‘유료서비스’의 남은 이용 기간 동안은 ‘유료서비스’를 정상 이용할 수 있습니다.
•	② '회원'은 ‘유료서비스’의 ‘구독취소’를 요청할 수 있습니다. ‘구독취소’ 시 ‘유료서비스’ 결제 금액의 결제일로부터 7일 전에는 100%, 결제일로부터 7일이 지난 경우에는 90%에 해당하는 금액을 환불받을 수 있습니다. 
•	③ 제1항 내지 제2항의 경우에 '회원'은 언제든지 앱 내 관리페이지, 고객센터 전화/1:1문의 등을 통해서 ‘구독취소’ 및 ‘정기구독’ 해지를 신청할 수 있으며 '회사'는 관계 법령 등에서 정하는 절차에 따라 이를 신속하게 처리합니다.
•	④ '회원'이 구매한 서비스를 이용한 이후에 중도 해지 또는 환불을 요청할 경우 환불이 불가합니다.
•	⑤ ‘회사’는 ‘회원’의 ‘구독취소’를 확인 후 환불 금액이 있을 경우, 원칙적으로 ‘회원’의 해당 의사표시를 수령한 날로부터 3영업일 이내에 결제수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고, ‘회원’이 결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, ‘회사’가 사전에 ‘회원’에게 공지한 경우 및 아래의 각 경우 등에는 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을 수 있습니다.
o	1. 신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납 확인일로부터 3영업일 이내
o	2. 결제수단 별 사업자가 ‘회사’와의 약정을 통하여 사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당 기한을 지난 환불의 경우
o	3. ‘회원’이 ‘유료서비스’를 이용한 후 이용으로 얻은 이익이 있거나 중도 해지하는 경우
o	4. ‘회원’이 환불 처리에 필요한 정보 내지 자료를 ‘회사’에 즉시 제공하지 않는 경우(예: 현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지 아니하거나, 타인 명의의 계좌를 제공하는 경우 등)
o	5. 해당 ‘회원’의 명시적 의사표시가 있는 경우
•	⑥ '회사'가 제12조 [회원탈퇴 및 회원자격의 정지]에 따라 계약을 해지하거나 서비스 이용을 제한한 후 환불해야 할 금액이 있을 경우에는 본 조에서 정한 환불 기준을 적용하여 공제하고 환불합니다. 다만, 이 경우 '회원'은 해당 조치에 대하여 '회사'가 정한 절차에 따라 이의 신청을 할 수 있으며, '회사'는 정당하다고 인정하는 경우 즉시 서비스 이용 재개 등을 합니다.
•	⑦ '회사'는 '회원'에게 ‘구독취소’를 이유로 위약금 또는 손해배상을 청구하지 않으며, '회원'의 계약해제∙해지는 손해배상의 청구에 영향을 미치지 않습니다. 다만, ‘회사’는 ‘회원’이 이용기간을 약정한 상품에 대한 해지에 대해서는 위약금을 청구할 수 있습니다.
•	⑧ ‘유료서비스’를 유지하고자 하는 ‘유료회원’은 이용요금의 체납 또는 결제수단의 연체가 발생하지 않도록 사전에 조치하여야 합니다.
•	⑨ ‘회원’이 전항을 위반하여 ‘유료서비스’의 신청 또는 동의에 따라 월 정기결제 중인 ‘유료서비스’를 이용하는 중에 ‘유료서비스’의 이용요금을 체납하는 경우 연체가 발생한 날 자동으로 이용권 해지가 될 수 있습니다.
•	⑩ ‘유료서비스’를 이용 중인 ‘회원’이 회원가입 계약을 해지하는 경우 해당 ‘유료서비스’는 즉시 해지되며, '회사'는 '회원’의 서비스 이용한 일 수, 이용내역 등을 차감 후 환불합니다. ‘회사’는 이 경우 ‘유료서비스’를 이용한 ‘회원’의 정보와 이용 내용은 관련 법령이 정하는 경우를 제외하고는 이 약관에 따라 처리합니다.
제22조 [하트력]
•	① '회원'의 계정이 ‘휴면 회원’ 상태일 경우 잔여 '하트력'은 사용할 수 없으며, ‘휴면 회원’에서 ‘일반 회원’으로 전환될 경우 잔여 '하트력'이 사용 가능한 상태로 복구됩니다.
•	② '회원'이 구독 서비스 이용 시 사용한 '하트력'에 따라 마이너스(-)로 적립된 '하트력'은 추가 청구될 수 있습니다.
•	③ '회원'은 '회사' 또는 제3자가 시행하는 이벤트, 프로모션에 참여하여 '하트력'을 적립할 수 있습니다.
•	④ '회원'은 구매한 ‘하트력’의 환불을 요청할 수 없습니다. ‘회사’는 ‘회원’이 ‘하트력’을 구매할 때에, 환불 및 취소가 불가능함에 대하여 안내합니다. 
•	⑤ '회원'은 구매한 ‘하트력’은 구매 후 3개월 이내에만 사용 가능하며, 기한 후 구매한 모든 하트력은 별도의 안내 없이 소멸됩니다. 
•	⑥ '하트력'은 현금으로 환급되지 않습니다. 또한 '하트력'은 '회원'이 탈퇴하거나 '회원'자격을 상실하는 경우 즉시 소멸합니다.
•	⑦ 프로모션, 이벤트 등을 통해 무상으로 제공된 '하트력'은 '회사'의 정책에 따라 그 유효기간을 별도로 특정합니다.
•	⑧ '회원'은 '하트력'을 타인에게 양도하거나 타인으로부터 양도받을 수 없습니다.
•	⑨ '회사'는 '하트력'을 이용하는 '회원'의 거래 금액에 대해서 거래 한도를 정할 수 있습니다.
•	⑩ '회사'는 회원이 이벤트성 '하트력'의 내용을 정확하게 이해하고 사용할 수 있도록 '하트력' 제공 시 관련 정보를 제공합니다.
________________________________________
제23조 [대금의 지급 및 과오금의 환급]
•	① ‘회원’은 ‘회사’가 제시한 결제방법에 따라 ‘유료서비스’의 대금을 지급하여야 합니다.
•	② ‘회원’이 대금을 결제함에 있어서 과오금을 지급한 경우 ‘회사’는 대금결제와 동일한 방법으로 과오금된 전액을 환급하여야 합니다. 다만, ‘회사’는 동일한 방법으로 과오금의 환급이 불가능할 때는 즉시 이를 ‘회원’에게 고지하고, ‘회원’이 선택한 방법으로 환급하여야 합니다.
•	③ ‘회원’의 책임 있는 사유로 과오금이 발생한 경우, ‘회사’는 과오금을 환급하는데 소요되는 비용을 합리적인 범위내에서 공제하고 환급할 수 있습니다.
•	④ ‘회사’는 ‘회원’의 과오금 환급청구를 거절할 경우 과오금이 없음을 입증하여야 합니다.
•	⑤ ‘회사’는 과오금의 세부 환불절차 및 기타 사항에 대하여 다음 각 호에 정하는 바에 따릅니다.
o	1. ‘회사’나 ‘회원’이 과오금의 발생사실을 안 때에는 전자우편, 서비스 홈페이지 등 회사가 제시한 방법에 따라 상대방에게 통보
o	2. ‘회사’는 ‘회원’에게 환불에 필요한 정보를 요청(회원 성명, 결제증빙서류, 전화번호, 환불 요청 계좌 등)
o	3. ‘회원’은 환불에 필요한 제2호의 정보를 ‘회사’에 제공
o	4. ‘회사’는 ‘회원’의 정보 제공일로부터 7일 이내 환불 처리(‘회원’의 명시적 의사표시가 있는 경우 차회 요금에서 상계)

</p>


    </div>
  )
}

import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

const auth = getAuth(app);

const db = getFirestore(app);  



export default function Register2() {
    const navigate = useNavigate()

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [birthMonth, setBirthMonth] = useState("");
    const [birthDay, setBirthDay] = useState("");
    //vec1: 일주, vec2: 월주, vec3: 년주
    const [vec1, setVec1] = useState("");
    const [vec2, setVec2] = useState("");
    const [vec3, setVec3] = useState("");
    //내가 만든 함수 쓰기 위한
    const [vec, setVec] = useState([]);
    const [svec, setSvec] = useState([]);
    const [basicStatusVec, setBasicStatusVec] =useState([]);
    // const [mbti_e, setMbti_e] = useState(true);
    // const [mbti_n, setMbti_n] = useState(true);
    // const [mbti_f, setMbti_f] = useState(true);
    // const [mbti_p, setMbti_p] = useState(true);
    // const [mbti_a, setMbti_a] = useState(true);


    //For 사주
    
    const month = [
      0, 21355, 42843, 64498, 86335, 108366, 130578, 152958,
      175471, 198077, 220728, 243370, 265955, 288432, 310767,
      332928, 354903, 376685, 398290, 419736, 441060, 462295,
      483493, 504693, 525949
];

  /**
   * 십간(十干) 데이터
   *
   */
  const gan = ['갑', '을', '병', '정', '무', '기', '경', '신', '임', '계']
  /**
   * 십간(十干) 한자 데이터
   * 
   */
  const hgan = ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸']
  /**
   * 십이지(十二支) 데이터
   * 
   */
  const ji = ['자', '축', '인', '묘', '진', '사', '오', '미', '신', '유', '술', '해'];
  /**
   * 십이지(十二支) 한자 데이터
   * 
   */
  const hji = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥']
  /**
   * 띠 데이터
   * 
   */
  const ddi = ['쥐', '소', '호랑이', '토끼', '용', '뱀', '말', '양', '원숭이', '닭', '개', '돼지']

  /**#@+
   * 병자년 경인월 신미일 기해시 입춘 데이터
   * 
   */
  const unityear  = 1996;
  const unitmonth = 2;
  const unitday   = 4;
  const unithour  = 22;
  const unitmin   = 8;
  const unitsec   = 0;
  /**#@-*/

  /**#@+
   * 병자년 데이터
   * 
   */
   const uygan = 2;
   const uyji  = 0;
   const uysu  = 12;
  /**#@-*/

  /**#@+
   * 경인년 데이터
   * 
   */
   const umgan = 6;
   const umji  = 2;
   const umsu  = 26;
  /**#@-*/

  /**#@+
   * 신미일 데이터
   * 
   */
   const udgan = 7;
   const udji  = 7;
   const udsu  = 7;
  /**#@-*/

  /**#@+
   * 기해시 데이터
   * 
   */
   const uhgan = 5;
   const uhji  = 11;
   const uhsu  = 35;
  /**#@-*/

  /**#@+
   * 정월 초하루 합삭 시간
   * 
   */
   const unitmyear  = 1996;
   const unitmmonth = 2;
   const unitmday   = 19;
   const unitmhour  = 8;
   const unitmmin   = 30;
   const unitmsec   = 0;
   const moonlength = 42524;
  /**#@-*/
  /**#@-*/
  // }}}

  // {{{ +-- public prpperties
  /**#@+
   */
  const month_st = [
      '입춘', '우수', '경칩', '춘분', '청명', '곡우',
      '입하', '소만', '망종', '하지', '소서', '대서',
      '입추', '처서', '백로', '추분', '한로', '상강',
      '입동', '소설', '대설', '동지', '소한', '대한',
      '입춘']

  /**
   */
  const hmonth_st = [
      '立春', '雨水', '驚蟄', '春分', '淸明', '穀雨',
      '立夏', '小滿', '芒種', '夏至', '小暑', '大暑',
      '立秋', '處暑', '白露', '秋分', '寒露', '霜降',
      '立冬', '小雪', '大雪', '冬至', '小寒', '大寒',
      '立春'
  ]

  /**
   */
  const ganji = [
      '갑자', '을축', '병인', '정묘', '무진', '기사', '경오', '신미', '임신', '계유', '갑술', '을해',
      '병자', '정축', '무인', '기묘', '경진', '신사', '임오', '계미', '갑신', '을유', '병술', '정해', 
      '무자', '기축', '경인', '신묘', '임진', '계사', '갑오', '을미', '병신', '정유', '무술', '기해', 
      '경자', '신축', '임인', '계묘', '갑진', '을사', '병오', '정미', '무신', '기유', '경술', '신해',
      '임자', '계축', '갑인', '을묘', '병진', '정사', '무오', '기미', '경신', '신유', '임술', '계해'
  ]
  /**
   */
  const hganji = [
      '甲子','乙丑','丙寅','丁卯','戊辰','己巳','庚午','辛未','壬申','癸酉','甲戌','乙亥',
      '丙子','丁丑','戊寅','己卯','庚辰','辛巳','壬午','癸未','甲申','乙酉','丙戌','丁亥',
      '戊子','己丑','庚寅','辛卯','壬辰','癸巳','甲午','乙未','丙申','丁酉','戊戌','己亥',
      '庚子','辛丑','壬寅','癸卯','甲辰','乙巳','丙午','丁未','戊申','己酉','庚戌','辛亥',
      '壬子','癸丑','甲寅','乙卯','丙辰','丁巳','戊午','己未','庚申','辛酉','壬戌','癸亥'
  ]

  /**
   */
  const week = ['일','월','화','수','목','금','토']
  /**
   */
  const hweek = ['日','月','火','水','木','金','土']
  /**
   * 
   */
  const s28days =[
      '角','亢','氐','房','心','尾','箕',
      '斗','牛','女','虛','危','室','壁',
      '奎','婁','胃','昴','畢','觜','參',
      '井','鬼','柳','星','張','翼','軫'
  ]
  const s28days_hangul = [
      '각', '항', '저', '방', '심', '미', '기',
      '두', '우', '녀', '허', '위', '실', '벽',
      '규', '수', '위', '묘', '필', '자', '삼',
      '정', '귀', '류', '성', '장', '익', '진'
]
  /**#@-*/
  // }}}

  // {{{ +-- protected (int) div ($a, $b)
  /**
   * 나누기
   *
   * 
   */
  const div = (a, b) => {
      return parseInt(a / b);
  }
  // }}}

  // {{{ +-- protected (int) disptimeday ($year, $month, $day)
  /**
   * year의 1월 1일부터 해당 일자까지의 날자수
   *

   */
  const disptimeday  = (year, month, day) => {
      var e = 0
      var i = 0

      for ( i=1; i<month; i++ ) {
          e += 31;
          if ( i == 2 || i == 4 || i == 6 || i == 9 || i == 11 ){
          e--;
          }
          if ( i == 2 ) {
              e -= 2;
              if ( (year % 4) == 0 ) {e++;}
              if ( (year % 100) == 0 ) {e--;}
              if ( (year % 400) == 0 ) {e++;}
              if ( (year % 4000) == 0 ) {e--;}
          }
      }
      e += day;

      return e;
  }
  // }}}

  // {{{ +-- protected (int) disp2days ($y1, $m1, $d1, $y2, $m2, $d2)
  /**
   * y1,m1,d1일부터 y2,m2,d2까지의 일수 계산
   *

   */
  const disp2days =(y1, m1, d1, y2, m2, d2) =>{
      var p1 = 0
      var p2 = 0
      var pn1 =0
      var pp1 = 0
      var pp2 = 0
      var pr = 0
      var dis = 0
      var ppp1 =0
      var ppp2 = 0
      var k = 0;

      if ( y2 > y1 ) {
          p1  = disptimeday(y1, m1, d1);
          pn1 = disptimeday(y1, 12, 31);
          p2  = disptimeday(y2, m2, d2);
          pp1 = y1;
          pp2 = y2;
          pr  = -1;
      } else {
          p1  = disptimeday (y2, m2, d2);
          pn1 = disptimeday (y2, 12, 31);
          p2  = disptimeday (y1, m1, d1);
          pp1 = y2;
          pp2 = y1;
          pr  = 1;
      }

      if ( y2 == y1 ){
          dis = p2 - p1;}
      else {
          dis = pn1 - p1;
          ppp1 = pp1 + 1;
          ppp2 = pp2 - 1;

          for ( k = ppp1; k <= ppp2; k++ ) {
              if ( k == -2000 && ppp2 > 1990 ) {
                  dis += 1457682;
                  k = 1991;
              } else if ( k == -1750 && ppp2 > 1990 ) {
                  dis += 1366371;
                  k = 1991;
              } else if ( k ==-1500 && ppp2 > 1990 ) {
                  dis += 1275060;
                  k = 1991;
              } else if ( k ==-1250 && ppp2 > 1990 ) {
                  dis += 1183750;
                  k = 1991;
              } else if ( k ==-1000 && ppp2 > 1990 ) {
                  dis += 1092439;
                  k = 1991;
              } else if ( k == -750 && ppp2 > 1990 ) {
                  dis += 1001128;
                  k = 1991;
              } else if ( k == -500 && ppp2 > 1990 ) {
                  dis += 909818;
                  k = 1991;
              } else if ( k == -250 && ppp2 > 1990 ) {
                  dis += 818507;
                  k = 1991;
              } else if ( k == 0 && ppp2 > 1990 ) {
                  dis += 727197;
                  k = 1991;
              } else if ( k == 250 && ppp2 > 1990 ) {
                  dis += 635887;
                  k = 1991;
              } else if ( k == 500 && ppp2 > 1990 ) {
                  dis += 544576;
                  k = 1991;
              } else if ( k == 750 && ppp2 > 1990 ) {
                  dis += 453266;
                  k = 1991;
              } else if ( k == 1000 && ppp2 > 1990 ) {
                  dis += 361955;
                  k = 1991;
              } else if ( k == 1250 && ppp2 > 1990 ) {
                  dis += 270644;
                  k = 1991;
              } else if ( k == 1500 && ppp2 > 1990 ) {
                  dis += 179334;
                  k = 1991;
              } else if ( k == 1750 && ppp2 > 1990 ) {
                  dis += 88023;
                  k = 1991;
              }

              dis += disptimeday(k, 12, 31);
          }

          dis += p2;
          dis *= pr;
      }

      return dis;
  }
  // }}}

  // {{{ +-- protected (int) getminbytime ($uy, $umm, $ud, $uh, $umin, $y1, $mo1, $d1, $h1, $mm1)
  /**
   * uy,umm,ud,uh,umin과 y1,mo1,d1,h1,mm1사이의 시간(분)
   * 
   */
  const getminbytime = (uy, umm, ud, uh, umin, y1, mo1, d1, h1, mm1)=> {
      var t = 0;

      var dispday = disp2days (uy, umm, ud, y1, mo1, d1);
      t = dispday * 24 * 60 + (uh - h1) * 60 + (umin - mm1);

      return t;
  }
  // }}}

  // {{{ +-- protected (array) getdatebymin ($tmin, $uyear, $umonth, $uday, $uhour, $umin)
  /**
   * uyear,umonth,uday,uhour,umin으로부터 tmin(분)떨이진 시점의
   * 년월일시분(태양력) 구하는 프로시져
   *
   
   */
  const getdatebymin =(tmin, uyear, umonth, uday, uhour, umin) => {
      var y1 = 0
      var mo1 =0
      var d1 = 0
      var h1 = 0
      var mi1 =0
      var t = 0;

      y1 = uyear - div(tmin, 525949);

      if ( tmin > 0 ) {
          y1 += 2 ;
          do {
              y1--;
              t = getminbytime(uyear, umonth, uday, uhour, umin, y1, 1, 1, 0, 0);
          } while ( t < tmin );

          mo1 = 13 ;
          do {
              mo1--;
              t =getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, 1, 0, 0);
          } while ( t < tmin );

          d1 = 32;
          do {
              d1--;
              t = getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, d1, 0, 0);
          } while ( t < tmin );

          h1 = 24 ;
          do {
              h1--;
              t = getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, d1, h1, 0);
          } while ( t < tmin );

          t =getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, d1, h1, 0);
          mi1 =  t - tmin;
      } else {
          y1 -= 2;
          do {
              y1++;
              t = getminbytime(uyear, umonth, uday, uhour, umin, y1, 1, 1, 0, 0);
          } while ( t >= tmin );

          y1--;
          mo1 = 0;
          do {
              mo1++;
              t = getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, 1, 0, 0);
          } while ( t >= tmin );

          mo1--;
          d1 = 0;
          do {
              d1 = d1 + 1;
              t = getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, d1, 0, 0);
          } while ( t >= tmin );

          d1--;
          h1 = -1 ;
          do {
              h1++;
              t = getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, d1, h1, 0);
          } while ( t >= tmin );

          h1--;
          t = getminbytime(uyear, umonth, uday, uhour, umin, y1, mo1, d1, h1, 0);
          mi1 = t - tmin;
      }

      return [y1, mo1, d1, h1, mi1]
  }
  // }}}

  // {{{ +-- protected (array) sydtoso24yd ($soloryear, $solormonth, $solorday, $solorhour, $solormin)
  /**
   * 그레고리력의 년월시일분으로 60년의 배수, 세차, 월건(태양력),
   * 일진, 시주를 구함
   *
   * 
   */
  const sydtoso24yd = (soloryear, solormonth, solorday, solorhour, solormin) => {
      var displ2min = getminbytime (
          unityear, unitmonth, unitday, unithour, unitmin,
          soloryear, solormonth, solorday, solorhour, solormin
      );
      var displ2day = disp2days(
          unityear, unitmonth, unitday, soloryear,
          solormonth, solorday
      );

      // 무인년(1996)입춘시점부터 해당일시까지 경과년수
      var so24 = div(displ2min, 525949);

      if ( displ2min >= 0 ){
          so24++;
      }
      // 년주 구하기
      var so24year = (so24 % 60) * -1;
      so24year += 12;
      if ( so24year < 0 ){
          so24year += 60;}
      else if ( so24year > 59 ){
          so24year -= 60;}

      var monthmin100 = displ2min % 525949;
      monthmin100 = 525949 - monthmin100;

      if ( monthmin100 < 0 ){
          monthmin100 += 525949}
      else if ( monthmin100 >= 525949 ){
          monthmin100 -= 525949}

      for ( i=0; i<=11; i++ ) {
          var j = i * 2;
          if ( (month[j] <= monthmin100) && (monthmin100 < month[j+2])){
              so24month = i}
      };

      // 월주 구하기
      var i = so24month;
      var t = so24year % 10 ;
      t %= 5 ;
      t = t * 12 + 2 + i;
      var so24month = t ;
      if ( so24month > 59 ) {
          so24month -= 60}

      var so24day = displ2day % 60;

      // 일주 구하기
      so24day *= -1;
      so24day += 7;
      if ( so24day < 0 ){
          so24day += 60;}
      else if ( so24day > 59 ){
          so24day -= 60;}

      if ( (solorhour == 0 || solorhour == 1) && solormin < 30 ){
          i = 0}

      else if ( (solorhour == 1 && solormin >= 30) || solorhour == 2
          || (solorhour == 3 && solormin < 30) ){
          i = 1}

      else if ( (solorhour == 3 && solormin >= 30) || solorhour == 4
          || (solorhour == 5 && solormin < 30) ){
          i = 2}

      else if ( (solorhour == 5 && solormin >= 30) || solorhour == 6
          || (solorhour == 7 && solormin < 30) ){
          i = 3}

      else if ( (solorhour == 7 && solormin >= 30) || solorhour == 8
          || (solorhour == 9 && solormin < 30) ){
          i = 4}

      else if ( (solorhour == 9 && solormin >= 30) || solorhour == 10
          || (solorhour == 11 && solormin < 30) ){
          i = 5}

      else if ( (solorhour == 11 && solormin >= 30) || solorhour == 12
          || (solorhour == 13 && solormin < 30) ){
          i = 6
          }
      else if ( (solorhour == 13 && solormin >= 30) || solorhour == 14
          || (solorhour == 15 && solormin < 30) ){
          i = 7}

      else if ( (solorhour == 15 && solormin >= 30) || solorhour == 16
          || (solorhour == 17 && solormin < 30) ){
          i = 8}

      else if ( (solorhour == 17 && solormin >= 30) || solorhour == 18
          || (solorhour == 19 && solormin < 30) ){
          i = 9}

      else if ( (solorhour == 19 && solormin >= 30) || solorhour == 20
          || (solorhour == 21 && solormin < 30) ){
          i = 10}

      else if ( (solorhour == 21 && solormin >= 30) || solorhour == 22
          || (solorhour == 23 && solormin < 30) ){
          i = 11}

      else if ( solorhour == 23 && solormin >= 30 )
      {
          so24day++;
          if ( so24day == 60 ){
              so24day = 0}
          i=0;
      }

      t = so24day % 10;
      t %= 5;
      t = t * 12 + i;
      var so24hour = t;

      // return [so24, so24year, so24month, so24day, so24hour]
      return [so24year, so24month, so24day]
  }

  const generate_vec = (vec1, vec2, vec3) => {
    var vec = new Array(6);
    if (vec1 == 0 || vec1 == 10 || vec1 == 20 || vec1==30 || vec1 == 40 || vec1==50){
        vec[0] = 0;
    }
    else if(vec1 == 1 || vec1 == 11 || vec1 == 21 || vec1==31 || vec1 == 41 || vec1==51){
        vec[0] = 1; 
    }
    else if(vec1 == 2 || vec1 == 12 || vec1 == 22 || vec1==32 || vec1 == 42 || vec1==52){
        vec[0] = 2; 
    }
    else if(vec1 == 3 || vec1 == 13 || vec1 == 23 || vec1==33 || vec1 == 43 || vec1==53){
        vec[0] = 3; 
    }
    else if(vec1 == 4 || vec1 == 14 || vec1 == 24 || vec1==34 || vec1 == 44 || vec1==54){
        vec[0] = 4; 
    }
    else if(vec1 == 5 || vec1 == 15 || vec1 == 25 || vec1==35 || vec1 == 45|| vec1==55){
        vec[0] = 5; 
    }
    else if(vec1 == 6 || vec1 == 16 || vec1 == 26|| vec1==36 || vec1 == 46|| vec1==56){
        vec[0] = 6; 
    }
    else if(vec1 == 7 || vec1 == 17 || vec1 == 27 || vec1==37 || vec1 == 47 || vec1==57){
        vec[0] = 7; 
    }
    else if(vec1 == 8 || vec1 == 18 || vec1 == 28 || vec1==38|| vec1 == 48 || vec1==58){
        vec[0] = 8; 
    }
    else{
        vec[0] = 9; 
    }
    
    if (vec2 == 0 || vec2 == 10 || vec2 == 20 || vec2==30 || vec2 == 40 || vec2==50){
        vec[2] = 0;
    }
    else if(vec2 == 1 || vec2 == 11 || vec2 == 21 || vec2==31 || vec2 == 41 || vec2==51){
        vec[2] = 1; 
    }
    else if(vec2 == 2 || vec2 == 12 || vec2 == 22 || vec2==32 || vec2 == 42 || vec2==52){
        vec[2] = 2; 
    }
    else if(vec2 == 3 || vec2 == 13 || vec2 == 23 || vec2==33 || vec2 == 43 || vec2==53){
        vec[2] = 3; 
    }
    else if(vec2 == 4 || vec2 == 14 || vec2 == 24 || vec2==34 || vec2 == 44 || vec2==54){
        vec[2] = 4; 
    }
    else if(vec2 == 5 || vec2 == 15 || vec2 == 25 || vec2==35 || vec2 == 45|| vec2==55){
        vec[2] = 5; 
    }
    else if(vec2 == 6 || vec2 == 16 || vec2 == 26|| vec2==36 || vec2 == 46|| vec2==56){
        vec[2] = 6; 
    }
    else if(vec2 == 7 || vec2 == 17 || vec2 == 27 || vec2==37 || vec2 == 47 || vec2==57){
        vec[2] = 7; 
    }
    else if(vec2 == 8 || vec2 == 18 || vec2 == 28 || vec2==38|| vec2 == 48 || vec2==58){
        vec[2] = 8; 
    }
    else{
        vec[2] = 9; 
    }
    
    if (vec3 == 0 || vec3 == 10 || vec3 == 20 || vec2==30 || vec3 == 40 || vec2==50){
        vec[4] = 0;
    }
    else if(vec3 == 1 || vec3 == 11 || vec3 == 21 || vec2==31 || vec3 == 41 || vec2==51){
        vec[4] = 1; 
    }
    else if(vec3 == 2 || vec3 == 12 || vec3 == 22 || vec2==32 || vec3 == 42 || vec2==52){
        vec[4] = 2; 
    }
    else if(vec3 == 3 || vec3 == 13 || vec3 == 23 || vec2==33 || vec3 == 43 || vec2==53){
        vec[4] = 3; 
    }
    else if(vec3 == 4 || vec3 == 14 || vec3 == 24 || vec2==34 || vec3 == 44 || vec2==54){
        vec[4] = 4; 
    }
    else if(vec3 == 5 || vec3 == 15 || vec3 == 25 || vec2==35 || vec3 == 45|| vec2==55){
        vec[4] = 5; 
    }
    else if(vec3 == 6 || vec3 == 16 || vec3 == 26|| vec2==36 || vec3 == 46|| vec2==56){
        vec[4] = 6; 
    }
    else if(vec3 == 7 || vec3 == 17 || vec3 == 27 || vec2==37 || vec3 == 47 || vec2==57){
        vec[4] = 7; 
    }
    else if(vec3 == 8 || vec3 == 18 || vec3 == 28 || vec2==38|| vec3 == 48 || vec2==58){
        vec[4] = 8; 
    }
    else{
        vec[4] = 9; 
    }
    
    //지지 시작
    if(vec1 == 0||vec1== 12||vec1 == 24|| vec1==36||vec1==48 ){
        vec[1]=0;
    }
    else if(vec1 == 1||vec1== 13||vec1 == 25|| vec1==37||vec1==49 ){
        vec[1]=1;
    }
    else if(vec1 == 2||vec1== 14||vec1 == 26|| vec1==38||vec1==50 ){
        vec[1]=2;
    }
    else if(vec1 == 3||vec1== 15||vec1 == 27|| vec1==39||vec1==51 ){
        vec[1]=3;
    }
    else if(vec1 == 4||vec1== 16||vec1 == 28|| vec1==40||vec1==52 ){
        vec[1]=4;
    }
    else if(vec1 == 5||vec1== 17||vec1 == 29|| vec1==41||vec1==53 ){
        vec[1]=5;
    }
    else if(vec1 == 6||vec1== 18||vec1 == 30|| vec1==42||vec1==54 ){
        vec[1]=6;
    }
    else if(vec1 == 7||vec1== 19||vec1 == 31|| vec1==43||vec1==55 ){
        vec[1]=7;
    }
    else if(vec1 == 8||vec1== 20||vec1 == 32|| vec1==44||vec1==56 ){
        vec[1]=8;
    }
    else if(vec1 == 9||vec1== 21||vec1 == 33|| vec1==45||vec1==57 ){
        vec[1]=9;
    }
    else if(vec1 == 10||vec1== 22||vec1 == 34|| vec1==46||vec1==58 ){
        vec[1]=10;
    }
    else{
        vec[1]=11;
    }
    
    if(vec2 == 0||vec2== 12||vec2 == 24|| vec2==36||vec2==48 ){
        vec[3]=0;
    }
    else if(vec2 == 1||vec2== 13||vec2 == 25|| vec2==37||vec2==49 ){
        vec[3]=1;
    }
    else if(vec2 == 2||vec2== 14||vec2 == 26|| vec2==38||vec2==50 ){
        vec[3]=2;
    }
    else if(vec2 == 3||vec2== 15||vec2 == 27|| vec2==39||vec2==51 ){
        vec[3]=3;
    }
    else if(vec2 == 4||vec2== 16||vec2 == 28|| vec2==40||vec2==52 ){
        vec[3]=4;
    }
    else if(vec2 == 5||vec2== 17||vec2 == 29|| vec2==41||vec2==53 ){
        vec[3]=5;
    }
    else if(vec2 == 6||vec2== 18||vec2 == 30|| vec2==42||vec2==54 ){
        vec[3]=6;
    }
    else if(vec2 == 7||vec2== 19||vec2 == 31|| vec2==43||vec2==55 ){
        vec[3]=7;
    }
    else if(vec2 == 8||vec2== 20||vec2 == 32|| vec2==44||vec2==56 ){
        vec[3]=8;
    }
    else if(vec2 == 9||vec2== 21||vec2 == 33|| vec2==45||vec2==57 ){
        vec[3]=9;
    }
    else if(vec2 == 10||vec2== 22||vec2 == 34|| vec2==46||vec2==58 ){
        vec[3]=10;
    }
    else{
        vec[3]=11;
    }
    
    if(vec3 == 0||vec3== 12||vec3 == 24|| vec3==36||vec3==48 ){
        vec[5]=0;
    }
    else if(vec3 == 1||vec3== 13||vec3 == 25|| vec3==37||vec3==49 ){
        vec[5]=1;
    }
    else if(vec3 == 2||vec3== 14||vec3 == 26|| vec3==38||vec3==50 ){
        vec[5]=2;
    }
    else if(vec3 == 3||vec3== 15||vec3 == 27|| vec3==39||vec3==51 ){
        vec[5]=3;
    }
    else if(vec3 == 4||vec3== 16||vec3 == 28|| vec3==40||vec3==52 ){
        vec[5]=4;
    }
    else if(vec3 == 5||vec3== 17||vec3 == 29|| vec3==41||vec3==53 ){
        vec[5]=5;
    }
    else if(vec3 == 6||vec3== 18||vec3 == 30|| vec3==42||vec3==54 ){
        vec[5]=6;
    }
    else if(vec3 == 7||vec3== 19||vec3 == 31|| vec3==43||vec3==55 ){
        vec[5]=7;
    }
    else if(vec3 == 8||vec3== 20||vec3 == 32|| vec3==44||vec3==56 ){
        vec[5]=8;
    }
    else if(vec3 == 9||vec3== 21||vec3 == 33|| vec3==45||vec3==57 ){
        vec[5]=9;
    }
    else if(vec3 == 10||vec3== 22||vec3 == 34|| vec3==46||vec3==58 ){
        vec[5]=10;
    }
    else{
        vec[5]=11;
    }

    return vec;
}

const generate_svec = (a, b, c, d, i, j) => {
    // a = vec[0] // 년간
    // b = vec[1] // 년지
    // c = vec[2] // 월간
    // d = vec[3] // 월지
    // i = vec[4] // 일간
    // j = vec[5] // 일지
    var svec = new Array(6);
    svec[4]=0;
    //일간이 갑목
    if(i ==0){
        //일지
        if(j==0){
            svec[5] = 8
        }
        else if(j==2){
            svec[5] =0
        }
        else if(j==4 || j ==10){
            svec[5] = 5
        }
        else if(j==6){
            svec[5] = 3
        }
        else if(j==8){
            svec[5] = 7
        }

        //월지
        if(d==0){
            svec[3] = 8
        }
        else if(d==1 || d==7){
            svec[3] = 4
        }
        else if(d==2){
            svec[3] = 0
        }
        else if(d==3){
            svec[3] = 1
        }
        else if(d==4 || d==10){
            svec[3] = 5
        }
        else if(d==5){
            svec[3] = 2
        }
        else if (d==6){
            svec[3] = 3
        }
        else if(d==8){
            svec[3] = 7
        }
        else if (d==9){
            svec[3] = 6
        }
        else if(d==11){
            svec[3] = 9
        }
        //년지
        if(b==0){
            svec[1] = 8
        }
        else if(b==1 || b==7){
            svec[1] = 4
        }
        else if(b==2){
            svec[1] = 0
        }
        else if(b==3){
            svec[1] = 1
        }
        else if(b==4 || b==10){
            svec[1] = 5
        }
        else if(b==5){
            svec[1] = 2
        }
        else if (b==6){
            svec[1] = 3
        }
        else if(b==8){
            svec[1] = 7
        }
        else if (b==9){
            svec[1] = 6
        }
        else if(b==11){
            svec[1] = 9
        }
        //월간
        if (c==0){
            svec[2] = 0
        }
        else if (c==1){
            svec[2] = 1
        }
        else if (c==2){
            svec[2]=2
        }
        else if(c==3){
            svec[2]=3
        }
        else if(c==4){
            svec[2] = 5
        }
        else if(c==5){
            svec[2] = 4
        }
        else if(c==6){
            svec[2]=7
        }
        else if (c==7){
            svec[2]=6
        }
        else if (c==8){
            svec[2]=9
        }
        else if(c==9){
            svec[2]=8
        }
        //년간
        if (a==0){
            svec[0] = 0
        }
        else if (a==1){
            svec[0] = 1
        }
        else if (a==2){
            svec[0]=2
        }
        else if(a==3){
            svec[0]=3
        }
        else if(a==4){
            svec[0] = 5
        }
        else if(a==5){
            svec[0] = 4
        }
        else if(a==6){
            svec[0]=7
        }
        else if (a==7){
            svec[0]=6
        }
        else if (a==8){
            svec[0]=9
        }
        else if(a==9){
            svec[0]=8
        }
    }
    //일간이 을목
    else if (i==1 ){
        //일지
        if(j==1||j==7){
            svec[5]=5
        }
        else if(j==3){
            svec[5]=0
        }
        else if(j==5){
            svec[5] =3
        }
        else if(j==9){
            svec[5]=7
        }
        else if(j==11){
            svec[5]=8
        }
        //월지
        if(d==0){
            svec[3]=9
        }
        else if(d==1 || d==7){
            svec[3]=5
        }
        else if(d==2){
            svec[3]=1
        }
        else if(d==3){
            svec[3]=0
        }
        else if(d==4||d==10){
            svec[3]=4
        }
        else if(d==5){
            svec[3]=3
        }
        else if(d==6){
            svec[3]=2
        }
        else if(d==8){
            svec[3]=6
        }
        else if(d==9){
            svec[3]=7
        }
        else if(d=11){
            svec[3]=8
        }
        //년지
        if(b==0){
            svec[1]=9
        }
        else if(b==1 || b==7){
            svec[1]=5
        }
        else if(b==2){
            svec[1]=1
        }
        else if(b==3){
            svec[1]=0
        }
        else if(b==4||b==10){
            svec[1]=4
        }
        else if(b==5){
            svec[1]=3
        }
        else if(b==6){
            svec[1]=2
        }
        else if(b==8){
            svec[1]=6
        }
        else if(b==9){
            svec[1]=7
        }
        else if(b=11){
            svec[1]=8
        }
        //월간
        if(c==0){
            svec[2]=1
        }
        else if(c==1){
            svec[2]=0
        }
        else if(c==2){
            svec[2]=3
        }
        else if(c==3){
            svec[2]=2
        }
        else if(c==4){
            svec[2]=4
        }
        else if(c==5){
            svec[2]=5
        }
        else if(c==6){
            svec[2]=6
        }
        else if(c==7){
            svec[2]=7
        }
        else if(c==8){
            svec[2]=8
        }
        else if(c==9){
            svec[2]=9
        }
        //년간
        if(a==0){
            svec[0]=1
        }
        else if(a==1){
            svec[0]=0
        }
        else if(a==2){
            svec[0]=3
        }
        else if(a==3){
            svec[0]=2
        }
        else if(a==4){
            svec[0]=4
        }
        else if(a==5){
            svec[0]=5
        }
        else if(a==6){
            svec[0]=6
        }
        else if(a==7){
            svec[0]=7
        }
        else if(a==8){
            svec[0]=8
        }
        else if(a==9){
            svec[0]=9
        }
    }
    //일간이 병화
    else if(i==2){
        //일지
        if(j==0){
            svec[5]=6
        }
        else if(j==2){
            svec[5]=9
        }
        else if(j==4||j==10){
            svec[5]=2
        }
        else if(j==6){
            svec[5]=1
        }
        else if(j==8){
            svec[5]=5
        }
        //월지
        if(d==0){
            svec[3]=6
        }
        else if(d==1||d==7){
            svec[3]=3
        }
        else if(d==2){
            svec[3]=9
        }
        else if(d==3){
            svec[3]=8
        }
        else if(d==4||d==10){
            svec[3]=2
        }
        else if(d==5){
            svec[3]=0
        }
        else if(d==6){
            svec[3]=1
        }
        else if(d==8){
            svec[3]=5
        }
        else if(d==9){
            svec[3]=4
        }
        else if(d==11){
            svec[3]=7
        }
        //년지
        if(b==0){
            svec[1]=6
        }
        else if(b==1||b==7){
            svec[1]=3
        }
        else if(b==2){
            svec[1]=9
        }
        else if(b==3){
            svec[1]=8
        }
        else if(b==4||b==10){
            svec[1]=2
        }
        else if(b==5){
            svec[1]=0
        }
        else if(b==6){
            svec[1]=1
        }
        else if(b==8){
            svec[1]=5
        }
        else if(b==9){
            svec[1]=4
        }
        else if(b==11){
            svec[1]=7
        }
        //월간
        if (c==0){
            svec[2]=9
        }
        else if(c==1){
            svec[2]=8
        }
        else if(c==2){
            svec[2]=0
        }
        else if(c==3){
            svec[2]=1
        }
        else if(c==4){
            svec[2]=2
        }
        else if(c==5){
            svec[2]=3
        }
        else if(c==6){
            svec[2]=5
        }
        else if(c==7){
            svec[2]=4
        }
        else if(c==8){
            svec[2]=7
        }
        else if(c==9){
            svec[2]=6
        }
        //년간
        if (a==0){
            svec[0]=9
        }
        else if(a==1){
            svec[0]=8
        }
        else if(a==2){
            svec[0]=0
        }
        else if(a==3){
            svec[0]=1
        }
        else if(a==4){
            svec[0]=2
        }
        else if(a==5){
            svec[0]=3
        }
        else if(a==6){
            svec[0]=5
        }
        else if(a==7){
            svec[0]=4
        }
        else if(a==8){
            svec[0]=7
        }
        else if(a==9){
            svec[0]=6
        }
    }
    //일간이 정화
    else if(i==3){
        //일지
        if(j==1||j==7){
            svec[5]=2
        }
        else if(j==3){
            svec[5]=9
        }
        else if(j==5){
            svec[5]=1
        }
        else if(j==9){
            svec[5]=5
        }
        else if(j==11){
            svec[5]=6
        }
        //월지
        if(d==0){
            svec[3]=7
        }
        else if(d==1||d==7){
            svec[3]=2
        }
        else if(d==2){
            svec[3]=8
        }
        else if(d==3){
            svec[3]=9
        }
        else if(d==4||d==10){
            svec[3]=3
        }
        else if(d==5){
            svec[3]=1
        }
        else if(d==6){
            svec[3]=0
        }
        else if(d==8){
            svec[3] = 4
        }
        else if(d==9){
            svec[3]=5
        }
        else if(d==11){
            svec[3]=6
        }
        //년지
        if(b==0){
            svec[1]=7
        }
        else if(b==1||b==7){
            svec[1]=2
        }
        else if(b==2){
            svec[1]=8
        }
        else if(b==3){
            svec[1]=9
        }
        else if(b==4||b==10){
            svec[1]=3
        }
        else if(b==5){
            svec[1]=1
        }
        else if(b==6){
            svec[1]=0
        }
        else if(b==8){
            svec[1] = 4
        }
        else if(b==9){
            svec[1]=5
        }
        else if(b==11){
            svec[1]=6
        }
        //월간
        if (c==0){
            svec[2]=8
        }
        else if(c==1){
            svec[2]=9
        }
        else if(c==2){
            svec[2]=1
        }
        else if(c==3){
            svec[2]=0
        }
        else if(c==4){
            svec[2]=3
        }
        else if(c==5){
            svec[2]=2
        }
        else if(c==6){
            svec[2]=4
        }
        else if(c==7){
            svec[2]=5
        }
        else if(c==8){
            svec[2]=6
        }
        else if(c==9){
            svec[2]=7
        }
        //년간
        if (a==0){
            svec[0]=8
        }
        else if(a==1){
            svec[0]=9
        }
        else if(a==2){
            svec[0]=1
        }
        else if(a==3){
            svec[0]=0
        }
        else if(a==4){
            svec[0]=3
        }
        else if(a==5){
            svec[0]=2
        }
        else if(a==6){
            svec[0]=4
        }
        else if(a==7){
            svec[0]=5
        }
        else if(a==8){
            svec[0]=6
        }
        else if(a==9){
            svec[0]=7
        }
    }
    //일간이 무토
    else if(i==4){
        //일지
        if(j==0){
            svec[5]=4
        }
        else if(j==1||j==7){
            svec[5]=1
        }
        else if(j==2){
            svec[5]=7
        }
        else if(j==3){
            svec[5]=6
        }
        else if (j==4||j==10){
            svec[5]=0
        }
        else if (j==5){
            svec[5]=9
        }
        else if(j==6){
            svec[5]=8
        }
        else if(j==8){
            svec[5]=2
        }
        else if(j==9){
            svec[5]=3
        }
        else if(j=11){
            svec[5]=5
        }
        //월지
        if(d==0){
            svec[3]=4
        }
        else if(d==1||d==7){
            svec[3]=1
        }
        else if(d==2){
            svec[3]=7
        }
        else if(d==3){
            svec[3]=6
        }
        else if (d==4||d==10){
            svec[3]=0
        }
        else if (d==5){
            svec[3]=9
        }
        else if(d==6){
            svec[3]=8
        }
        else if(d==8){
            svec[3]=2
        }
        else if(d==9){
            svec[3]=3
        }
        else if(d=11){
            svec[3]=5
        }
        //년지
        if(b==0){
            svec[1]=4
        }
        else if(b==1||b==7){
            svec[1]=1
        }
        else if(b==2){
            svec[1]=7
        }
        else if(b==3){
            svec[1]=6
        }
        else if (b==4||b==10){
            svec[1]=0
        }
        else if (b==5){
            svec[1]=9
        }
        else if(b==6){
            svec[1]=8
        }
        else if(b==8){
            svec[1]=2
        }
        else if(b==9){
            svec[1]=3
        }
        else if(b=11){
            svec[1]=5
        }
        //월간
        if(c==0){
            svec[2]=7
        }
        else if(c==1){
            svec[2]=6
        }
        else if(c==2){
            svec[2]=9
        }
        else if(c==3){
            svec[2]=8
        }
        else if(c==4){
            svec[2]=0
        }
        else if(c==5){
            svec[2]=1
        }
        else if(c==6){
            svec[2]=2
        }
        else if(c==7){
            svec[2]=3
        }
        else if(c==8){
            svec[2]=5
        }
        else if(c==9){
            svec[2]=4
        }
        //년간
        if(a==0){
            svec[0]=7
        }
        else if(a==1){
            svec[0]=6
        }
        else if(a==2){
            svec[0]=9
        }
        else if(a==3){
            svec[0]=8
        }
        else if(a==4){
            svec[0]=0
        }
        else if(a==5){
            svec[0]=1
        }
        else if(a==6){
            svec[0]=2
        }
        else if(a==7){
            svec[0]=3
        }
        else if(a==8){
            svec[0]=5
        }
        else if(a==9){
            svec[0]=4
        }
    }
    //일간이 기토
    else if(i==5){
        //일지
        if(j==0){
            svec[5]=5
        }
        else if(j==1||j==7){
            svec[5]=0
        }
        else if(j==2){
            svec[5]=6
        }
        else if(j==3){
            svec[5]=7
        }
        else if(j==4||j==10){
            svec[5]=1
        }
        else if(j==5){
            svec[5]=8
        }
        else if(j==6){
            svec[5]=9
        }
        else if(j==8){
            svec[5]=3
        }
        else if(j==9){
            svec[5]=2
        }
        else if(j==11){
            svec[5]=4
        }
        //월지
        if(d==0){
            svec[3]=5
        }
        else if(d==1||d==7){
            svec[3]=0
        }
        else if(d==2){
            svec[3]=6
        }
        else if(d==3){
            svec[3]=7
        }
        else if(d==4||d==10){
            svec[3]=1
        }
        else if(d==5){
            svec[3]=8
        }
        else if(d==6){
            svec[3]=9
        }
        else if(d==8){
            svec[3]=3
        }
        else if(d==9){
            svec[3]=2
        }
        else if(d==11){
            svec[3]=4
        }
        //년지
        if(b==0){
            svec[1]=5
        }
        else if(b==1||b==7){
            svec[1]=0
        }
        else if(b==2){
            svec[1]=6
        }
        else if(b==3){
            svec[1]=7
        }
        else if(b==4||b==10){
            svec[1]=1
        }
        else if(b==5){
            svec[1]=8
        }
        else if(b==6){
            svec[1]=9
        }
        else if(b==8){
            svec[1]=3
        }
        else if(b==9){
            svec[1]=2
        }
        else if(b==11){
            svec[1]=4
        }
        //월간
        if(c==0){
            svec[2]=6
        }
        else if(c==1){
            svec[2]=7
        }
        else if(c==2){
            svec[2]=8
        }
        else if(c==3){
            svec[2]=9
        }
        else if(c==4){
            svec[2]=1
        }
        else if(c==5){
            svec[2]=0
        }
        else if(c==6){
            svec[2]=3
        }
        else if(c==7){
            svec[2]=2
        }
        else if(c==8){
            svec[2]=4
        }
        else if(c==9){
            svec[2]=5
        }
        //년간
        if(a==0){
            svec[0]=6
        }
        else if(a==1){
            svec[0]=7
        }
        else if(a==2){
            svec[0]=8
        }
        else if(a==3){
            svec[0]=9
        }
        else if(a==4){
            svec[0]=1
        }
        else if(a==5){
            svec[0]=0
        }
        else if(a==6){
            svec[0]=3
        }
        else if(a==7){
            svec[0]=2
        }
        else if(a==8){
            svec[0]=4
        }
        else if(a==9){
            svec[0]=5
        }
    }
    //일간이 경금
    else if(i==6){
        //일지
        if(j==0){
            svec[5]=3
        }
        else if(j==1||j==7){
            svec[5]=8
        }
        else if(j==2){
            svec[5]=5
        }
        else if(j==3){
            svec[5]=4
        }
        else if(j==4||j==10){
            svec[5]=9
        }
        else if(j==5){
            svec[5]=7
        }
        else if(j==6){
            svec[5]=6
        }
        else if(j==8){
            svec[5]=0
        }
        else if(j==9){
            svec[5]=1
        }
        else if(j==11){
            svec[5]=2
        }
        //월지
        if(d==0){
            svec[3]=3
        }
        else if(d==1||d==7){
            svec[3]=8
        }
        else if(d==2){
            svec[3]=5
        }
        else if(d==3){
            svec[3]=4
        }
        else if(d==4||d==10){
            svec[3]=9
        }
        else if(d==5){
            svec[3]=7
        }
        else if(d==6){
            svec[3]=6
        }
        else if(d==8){
            svec[3]=0
        }
        else if(d==9){
            svec[3]=1
        }
        else if(d==11){
            svec[3]=2
        }
        //년지
        if(b==0){
            svec[1]=3
        }
        else if(b==1||b==7){
            svec[1]=8
        }
        else if(b==2){
            svec[1]=5
        }
        else if(b==3){
            svec[1]=4
        }
        else if(b==4||b==10){
            svec[1]=9
        }
        else if(b==5){
            svec[1]=7
        }
        else if(b==6){
            svec[1]=6
        }
        else if(b==8){
            svec[1]=0
        }
        else if(b==9){
            svec[1]=1
        }
        else if(b==11){
            svec[1]=2
        }
        //월간
        if(c==0){
            svec[2]=5
        }
        else if(c==1){
            svec[2]=4
        }
        else if(c==2){
            svec[2]=7
        }
        else if(c==3){
            svec[2]=6
        }
        else if(c==4){
            svec[2]=9
        }
        else if(c==5){
            svec[2]=8
        }
        else if(c==6){
            svec[2]=0
        }
        else if(c==7){
            svec[2]=1
        }
        else if(c==8){
            svec[2]=2
        }
        else if(c==9){
            svec[2]=3
        }
        //년간
        if(a==0){
            svec[0]=5
        }
        else if(a==1){
            svec[0]=4
        }
        else if(a==2){
            svec[0]=7
        }
        else if(a==3){
            svec[0]=6
        }
        else if(a==4){
            svec[0]=9
        }
        else if(a==5){
            svec[0]=8
        }
        else if(a==6){
            svec[0]=0
        }
        else if(a==7){
            svec[0]=1
        }
        else if(a==8){
            svec[0]=2
        }
        else if(a==9){
            svec[0]=3
        }
    }
    //일간이 신금
    else if(i==7){
        //일지
        if(j==0){
            svec[5]=2
        }
        else if(j==1||j==7){
            svec[5]=9
        }
        else if(j==2){
            svec[5]=4
        }
        else if(j==3){
            svec[5]=5
        }
        else if(j==4||j==10){
            svec[5]=8
        }
        else if(j==5){
            svec[5]=6
        }
        else if(j==6){
            svec[5]=7
        }
        else if(j==8){
            svec[5]=1
        }
        else if(j==9){
            svec[5]=0
        }
        else if(j==11){
            svec[5]=3
        }
        //월지
        if(d==0){
            svec[3]=2
        }
        else if(d==1||d==7){
            svec[3]=9
        }
        else if(d==2){
            svec[3]=4
        }
        else if(d==3){
            svec[3]=5
        }
        else if(d==4||d==10){
            svec[3]=8
        }
        else if(d==5){
            svec[3]=6
        }
        else if(d==6){
            svec[3]=7
        }
        else if(d==8){
            svec[3]=1
        }
        else if(d==9){
            svec[3]=0
        }
        else if(d==11){
            svec[3]=3
        }
        //년지
        if(b==0){
            svec[1]=2
        }
        else if(b==1||b==7){
            svec[1]=9
        }
        else if(b==2){
            svec[1]=4
        }
        else if(b==3){
            svec[1]=5
        }
        else if(b==4||b==10){
            svec[1]=8
        }
        else if(b==5){
            svec[1]=6
        }
        else if(b==6){
            svec[1]=7
        }
        else if(b==8){
            svec[1]=1
        }
        else if(b==9){
            svec[1]=0
        }
        else if(b==11){
            svec[1]=3
        }
        //월간
        if(c==0){
            svec[2]=4
        }
        else if(c==1){
            svec[2]=5
        }
        else if(c==2){
            svec[2]=6
        }
        else if(c==3){
            svec[2]=7
        }
        else if(c==4){
            svec[2]=8
        }
        else if(c==5){
            svec[2]=9
        }
        else if(c==6){
            svec[2]=1
        }
        else if(c==7){
            svec[2]=0
        }
        else if(c==8){
            svec[2]=3
        }
        else if(c==9){
            svec[2]=2
        }
        //년간
        if(a==0){
            svec[0]=4
        }
        else if(a==1){
            svec[0]=5
        }
        else if(a==2){
            svec[0]=6
        }
        else if(a==3){
            svec[0]=7
        }
        else if(a==4){
            svec[0]=8
        }
        else if(a==5){
            svec[0]=9
        }
        else if(a==6){
            svec[0]=1
        }
        else if(a==7){
            svec[0]=0
        }
        else if(a==8){
            svec[0]=3
        }
        else if(a==9){
            svec[0]=2
        }
    }
    //일간이 임수
    else if(i==8){
        //일지
        if(j==0){
            svec[5]=1
        }
        else if(j==1||j==7){
            svec[5]=6
        }
        else if(j==2){
            svec[5]=2
        }
        else if(j==3){
            svec[5]=3
        }
        else if(j==4||j==10){
            svec[5]=7
        }
        else if(j==5){
            svec[5]=5
        }
        else if(j==6){
            svec[5]=4
        }
        else if(j==8){
            svec[5]=9
        }
        else if(j==9){
            svec[5]=8
        }
        else if(j==11){
            svec[5]=0
        }
        //월지
        if(d==0){
            svec[3]=1
        }
        else if(d==1||d==7){
            svec[3]=6
        }
        else if(d==2){
            svec[3]=2
        }
        else if(d==3){
            svec[3]=3
        }
        else if(d==4||d==10){
            svec[3]=7
        }
        else if(d==5){
            svec[3]=5
        }
        else if(d==6){
            svec[3]=4
        }
        else if(d==8){
            svec[3]=9
        }
        else if(d==9){
            svec[3]=8
        }
        else if(d==11){
            svec[3]=0
        }
        //년지
        if(b==0){
            svec[1]=1
        }
        else if(b==1||b==7){
            svec[1]=6
        }
        else if(b==2){
            svec[1]=2
        }
        else if(b==3){
            svec[1]=3
        }
        else if(b==4||b==10){
            svec[1]=7
        }
        else if(b==5){
            svec[1]=5
        }
        else if(b==6){
            svec[1]=4
        }
        else if(b==8){
            svec[1]=9
        }
        else if(b==9){
            svec[1]=8
        }
        else if(b==11){
            svec[1]=0
        }
        //월간
        if(c==0){
            svec[2]=2
        }
        else if(c==1){
            svec[2]=3
        }
        else if(c==2){
            svec[2]=5
        }
        else if(c==3){
            svec[2]=4
        }
        else if(c==4){
            svec[2]=7
        }
        else if(c==5){
            svec[2]=6
        }
        else if(c==6){
            svec[2]=9
        }
        else if(c==7){
            svec[2]=8
        }
        else if(c==8){
            svec[2]=0
        }
        else if(c==9){
            svec[2]=1
        }
        //년간
        if(a==0){
            svec[0]=2
        }
        else if(a==1){
            svec[0]=3
        }
        else if(a==2){
            svec[0]=5
        }
        else if(a==3){
            svec[0]=4
        }
        else if(a==4){
            svec[0]=7
        }
        else if(a==5){
            svec[0]=6
        }
        else if(a==6){
            svec[0]=9
        }
        else if(a==7){
            svec[0]=8
        }
        else if(a==8){
            svec[0]=0
        }
        else if(a==9){
            svec[0]=1
        }
    }
    //일간이 계수
    else if(i==9){
        //일지
        if(j==0){
            svec[5]=0
        }
        else if(j==1||j==7){
            svec[5]=7
        }
        else if(j==2){
            svec[5]=3
        }
        else if(j==3){
            svec[5]=2
        }
        else if(j==4||j==10){
            svec[5]=6
        }
        else if(j==5){
            svec[5]=4
        }
        else if(j==6){
            svec[5]=5
        }
        else if(j==8){
            svec[5]=8
        }
        else if(j==9){
            svec[5]=9
        }
        else if(j==11){
            svec[5]=1
        }
        //월지
        if(d==0){
            svec[3]=0
        }
        else if(d==1||d==7){
            svec[3]=7
        }
        else if(d==2){
            svec[3]=3
        }
        else if(d==3){
            svec[3]=2
        }
        else if(d==4||d==10){
            svec[3]=6
        }
        else if(d==5){
            svec[3]=4
        }
        else if(d==6){
            svec[3]=5
        }
        else if(d==8){
            svec[3]=8
        }
        else if(d==9){
            svec[3]=9
        }
        else if(d==11){
            svec[3]=1
        }
        //년지
        if(b==0){
            svec[1]=0
        }
        else if(b==1||b==7){
            svec[1]=7
        }
        else if(b==2){
            svec[1]=3
        }
        else if(b==3){
            svec[1]=2
        }
        else if(b==4||b==10){
            svec[1]=6
        }
        else if(b==5){
            svec[1]=4
        }
        else if(b==6){
            svec[1]=5
        }
        else if(b==8){
            svec[1]=8
        }
        else if(b==9){
            svec[1]=9
        }
        else if(b==11){
            svec[1]=1
        }
        //월간
        if(c==0){
            svec[2]=3
        }
        else if(c==1){
            svec[2]=2
        }
        else if(c==2){
            svec[2]=4
        }
        else if(c==3){
            svec[2]=5
        }
        else if(c==4){
            svec[2]=6
        }
        else if(c==5){
            svec[2]=7
        }
        else if(c==6){
            svec[2]=8
        }
        else if(c==7){
            svec[2]=9
        }
        else if(c=8){
            svec[2]=1
        }
        else if(c=9){
            svec[2]=0
        }
        //년간
        if(a==0){
            svec[0]=3
        }
        else if(a==1){
            svec[0]=2
        }
        else if(a==2){
            svec[0]=4
        }
        else if(a==3){
            svec[0]=5
        }
        else if(a==4){
            svec[0]=6
        }
        else if(a==5){
            svec[0]=7
        }
        else if(a==6){
            svec[0]=8
        }
        else if(a==7){
            svec[0]=9
        }
        else if(a=8){
            svec[0]=1
        }
        else if(a=9){
            svec[0]=0
        }
    }
    return svec
}

const generate_basicStatusVec = (a,b,c,d,i,j) => {
    //a:년간, b:년지, c:월간, d:월지, i:일간, j:일지
    var basicStatusVec = new Array(20);
    //index 0 : 비견 유무(일간 빼고)
    if(a==0||b==0||c==0||d==0||j==0){
        basicStatusVec[0]=1
    }
    else{
        basicStatusVec[0]=0
    }
    //겁재 유무
    if(a==1||b==1||c==1||d==1||j==1){
        basicStatusVec[1]=1
    }
    else{
        basicStatusVec[1]=0
    }
    //식신 유무
    if(a==2||b==2||c==2||d==2||j==2){
        basicStatusVec[4]=1
    }
    else{
        basicStatusVec[4]=0
    }
    //상관 유무
    if(a==3||b==3||c==3||d==3||j==3){
        basicStatusVec[5]=1
    }
    else{
        basicStatusVec[5]=0
    }
    //정재 유무
    if(a==4||b==4||c==4||d==4||j==4){
        basicStatusVec[8]=1
    }
    else{
        basicStatusVec[8]=0
    }
    //편재 유무
    if(a==5||b==5||c==5||d==5||j==5){
        basicStatusVec[9]=1
    }
    else{
        basicStatusVec[9]=0
    }
    //정관 유무
    if(a==6||b==6||c==6||d==6||j==6){
        basicStatusVec[12]=1
    }
    else{
        basicStatusVec[12]=0
    }
    //편관 유무
    if(a==7||b==7||c==7||d==7||j==7){
        basicStatusVec[13]=1
    }
    else{
        basicStatusVec[13]=0
    }
    //정인 유무
    if(a==8||b==8||c==8||d==8||j==8){
        basicStatusVec[16]=1
    }
    else{
        basicStatusVec[16]=0
    }
    //편인 유무
    if(a==9||b==9||c==9||d==9||j==9){
        basicStatusVec[17]=1
    }
    else{
        basicStatusVec[17]=0
    }

    //무비겁
    if(basicStatusVec[0]==0 && basicStatusVec[1]==0){
        basicStatusVec[3]=1
    }
    else{
        basicStatusVec[3]=0
    }
    //무식상
    if(basicStatusVec[4]==0 && basicStatusVec[5]==0){
        basicStatusVec[7]=1
    }
    else{
        basicStatusVec[7]=0
    }
    //무재
    if(basicStatusVec[8]==0 && basicStatusVec[9]==0){
        basicStatusVec[11]=1
    }
    else{
        basicStatusVec[11]=0
    }
    //무관
    if(basicStatusVec[12]==0 && basicStatusVec[13]==0){
        basicStatusVec[15]=1
    }
    else{
        basicStatusVec[15]=0
    }
    //무인성
    if(basicStatusVec[16]==0 && basicStatusVec[17]==0){
        basicStatusVec[19]=1
    }
    else{
        basicStatusVec[19]=0
    }

    //점수 var (비겁 A, 식상 B, 재성 C, 관성 D, 인성 E)
    var A=0;
    var B=0;
    var C=0;
    var D=0;
    var E=0;
    //비겁 점수 합
    if(a==0||a==1||b==0||b==1||c==0||c==1) {
        A += 3;
    }
    if(j==0||j==1||i==0){
        A += 4;
    }
    if(d==0||d==1){
        A += 8;
    }
    //식상 점수 합
    if(a==2||a==3||b==2||b==3||c==2||c==3) {
        B += 3;
    }
    if(j==2||j==3){
        B += 4;
    }
    if(d==2||d==3){
        B += 8;
    }
    //재성 점수 합
    if(a==4||a==5||b==4||b==5||c==4||c==5) {
        C += 3;
    }
    if(j==4||j==5){
        C += 4;
    }
    if(d==4||d==5){
        C += 8;
    }
    //관성 점수 합
    if(a==6||a==7||b==6||b==7||c==6||c==7) {
        D += 3;
    }
    if(j==6||j==7){
        D += 4;
    }
    if(d==6||d==7){
        D += 8;
    }
    //인성 점수 합
    if(a==8||a==9||b==8||b==9||c==8||c==9) {
        E += 3;
    }
    if(j==8||j==9){
        E += 4;
    }
    if(d==8||d==9){
        E += 8;
    }

    //합산 결과
    if (Number(A)>9){
        basicStatusVec[2]=1
    }
    else{
        basicStatusVec[2]=0
    }
    if (Number(B)>10){
        basicStatusVec[6]=1
    }
    else{
        basicStatusVec[6]=0
    }
    if (Number(C)>10){
        basicStatusVec[10]=1
    }
    else{
        basicStatusVec[10]=0
    }
    if (Number(D)>10){
        basicStatusVec[14]=1
    }
    else{
        basicStatusVec[14]=0
    }
    if (Number(E)>10){
        basicStatusVec[18]=1
    }
    else{
        basicStatusVec[18]=0
    }

    console.log(A);
    console.log(B);
    console.log(C);
    console.log(D);
    console.log(E);
    
    return basicStatusVec;
}



    const handleRegister = (e) => {
        var arr = [];
        //내가 만든 벡터 순차적으로 넣기
        var arr2=[];
        var arr3=[];
        var arr4=[];
        e.preventDefault();
        createUserWithEmailAndPassword(auth, email, password)
        .then(cred => {
            console.log('user created:', cred.user)
            console.log(sydtoso24yd(Number(birthYear), Number(birthMonth), Number(birthDay), 9, 9)); 
            arr = sydtoso24yd(Number(birthYear), Number(birthMonth), Number(birthDay), 9, 9);
            arr2 = generate_vec(arr[0], arr[1], arr[2])
            setVec(arr2);
            arr3 = generate_svec(arr2[0], arr2[1], arr2[2], arr2[3], arr2[4], arr2[5]);
            setSvec(arr3);
            arr4 = generate_basicStatusVec(arr3[0], arr3[1], arr3[2], arr3[3], arr3[4], arr3[5])
            setBasicStatusVec(arr4);
          })
            .then(()=>{
                setDoc(doc(db, "users", auth.currentUser.uid), {
                    name: name,
                    email: email,
                    year : birthYear,
                    month : birthMonth,
                    day : birthDay,
                    level : 0,
                    heart : 0,
                    status : "Visitor",
                    //레벨 나중에 업그래이드 위해서
                    numJournals : 0,
                    numTemplates : 0,
                    numBotAnswers : 0 
                    // y: arr[0],
                    // m : arr[1],
                    // d: arr[2]
                    // vec1 : arr[0],
                    // vec2 : arr[1],
                    // vec3 : arr[2],
                    //내가 만든 벡터
                    // vec:arr2,
                    // svec:arr3,
                    // basicStatusVec:arr4
                    // mbti_e : mbti_e,
                    // mbti_n : mbti_n,
                    // mbti_f : mbti_f,
                    // mbti_p : mbti_p,
                    // mbti_a : mbti_a
                  });
                  setDoc(doc(db, "usersInfos", auth.currentUser.uid), {
                    name: name,
                    // email: email,
                    // year : birthYear,
                    // month : birthMonth,
                    // day : birthDay,
                    vec1 : arr[0],
                    vec2 : arr[1],
                    vec3 : arr[2],
                    //내가 만든 벡터
                    vec:arr2,
                    svec:arr3,
                    basicStatusVec:arr4
                    // mbti_e : mbti_e,
                    // mbti_n : mbti_n,
                    // mbti_f : mbti_f,
                    // mbti_p : mbti_p,
                    // mbti_a : mbti_a
                  });
                  setDoc(doc(db, "usersMaterials", auth.currentUser.uid), {
                    name: name,
                    //Initial User Materials
                    prompts : [],
                    narration : "00님, 당신의 아지트에 오신 것을 환영합니다!"
                    // email: email,
                    // year : birthYear,
                    // month : birthMonth,
                    // day : birthDay,
                    // vec1 : arr[0],
                    // vec2 : arr[1],
                    // vec3 : arr[2],
                    //내가 만든 벡터
                    // vec:arr2,
                    // svec:arr3,
                    // basicStatusVec:arr4
                    // mbti_e : mbti_e,
                    // mbti_n : mbti_n,
                    // mbti_f : mbti_f,
                    // mbti_p : mbti_p,
                    // mbti_a : mbti_a
                  });
                  setDoc(doc(db, "usersTexts", auth.currentUser.uid), {
                    name: name
                  });
            })
            .then(ref=>{
                setEmail("");
                setPassword("");
                setName("");
                setBirthYear("");
                setBirthMonth("");
                setBirthDay("");
            })
            .catch((error) => {
                setEmail("");
                setPassword("");
                setName("");
                alert(error.message);
                console.log(error.message);
            })

    }

    return (
        <div className="authContainer">
          <p>당신만의 유니버스를 창조해보세요!</p>
            <form onSubmit={handleRegister}>
                <input type="text" placeholder="이름을 입력하세요..." value={name} onChange={(e)=>setName(e.target.value)} required/>
                <input type="email" placeholder="이메일을 입력하세요..." value={email} onChange={(e)=>setEmail(e.target.value)} required/>
                <input type="password" placeholder="비밀번호를 입력하세요..." value={password} onChange={(e)=>setPassword(e.target.value)} required/>
                <input type="text" placeholder="태어난 연도" value={birthYear} onChange={(e)=>setBirthYear(e.target.value)} required/>
                <input type="text" placeholder="태어난 달" value={birthMonth} onChange={(e)=>setBirthMonth(e.target.value)} required/>
                <input type="text" placeholder="태어난 날" value={birthDay} onChange={(e)=>setBirthDay(e.target.value)} required/>
                <input type="submit" value="눌러서 회원가입" />
            </form>

            <div><button onClick={() => navigate('/register3')}>이동</button></div>
            <div>이미 유니버스 계정이 있으신가요?</div>
            <a href="/signin">로그인</a>
            <div className="stretch"></div>
        </div>
    )
}



import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useParams, useNavigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;

export default function JournalDetails() {
    const navigate = useNavigate()
    const [journal, setJournal] = useState([]);
    const { id } = useParams()

    const [comment1, setComment1] = useState([]);
    const [comment2, setComment2] = useState([]);
    const [comment3, setComment3] = useState([]);
    const [comment4, setComment4] = useState([]);
    const [comment5, setComment5] = useState([]);
    const [comment6, setComment6] = useState([]);

    const handleDelete = (id) => {
      navigate('/')
      const docRef = doc(db, 'posts', id)

      deleteDoc(docRef)
      .then(()=> console.log("delete succedeed"))
      .catch(error => {
          alert(error);
      });

    }


    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'posts', id), (doc) => {
            setJournal(doc.data())
            console.log('**')
            console.log(doc.data())
            setComment1(doc.data().comment1)
            setComment2(doc.data().comment2)
            setComment3(doc.data().comment3)
            setComment4(doc.data().comment4)
            setComment5(doc.data().comment5)
            setComment6(doc.data().comment6)
        });
      }, []);
    
    return (
        <div className="containerBasic">
          <h2>{journal.year}년 {journal.numMonth}월 {journal.day}일 {journal.day_week}</h2>
          <p>{journal.text}</p>
          <div className="comments">
            <div className="onecomment">
              {(comment1|| [])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
              {(comment1|| [])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
              {(comment1|| [])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
              {(comment1|| [])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
              {(comment1|| [])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
              {(comment1|| [])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
              <p>{(comment1|| [])[1]}</p>
            </div>
            <div className="onecomment">
              {(comment2||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
              {(comment2||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
              {(comment2||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
              {(comment2||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
              {(comment2||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
              {(comment2||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
              <p>{(comment2||[])[1]}</p>
            </div>
            <div className="onecomment">
              {(comment3||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
              {(comment3||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
              {(comment3||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
              {(comment3||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
              {(comment3||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
              {(comment3||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
              <p>{(comment3||[])[1]}</p>
            </div>
            <div className="onecomment">
              {(comment4||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
              {(comment4||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
              {(comment4||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
              {(comment4||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
              {(comment4||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
              {(comment4||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
              <p>{(comment4||[])[1]}</p>
            </div>
            <div className="onecomment">
              {(comment5||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
              {(comment5||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
              {(comment5||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
              {(comment5||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
              {(comment5||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
              {(comment5||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
              <p>{(comment5||[])[1]}</p>
            </div>
            <div className="onecomment">
              {(comment6||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
              {(comment6||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
              {(comment6||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
              {(comment6||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
              {(comment6||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
              {(comment6||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
              <p>{(comment6||[])[1]}</p>
            </div> 
          </div>
          
          <div>
            <p>{journal.situation}</p>
            <p>{journal.emotion}</p>
          </div>
          
          <br/>
          <br/>

          <div>
            <button onClick={()=> handleDelete(id)}>글 삭제</button>
          </div>
        </div>
  )
}


import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, limit, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;


export default function Journals() {

    const [journals, setJournals] = useState([]);


    useEffect(() => {
        // queries
        const q = query(colPostRef, where("uid", "==", auth.currentUser.uid), orderBy('createdAt', "desc"))

        // realtime collection data
        onSnapshot(q, (snapshot) => {
        let posts = []
        snapshot.docs.forEach(doc => {
            posts.push({ ...doc.data(), id: doc.id })
        })
        console.log(posts);
        setJournals(posts);
        })
      }, []);  
  return (
        <div className="containerBasic2">
            <ul>
              {journals && journals.map(item => (
                <div key={item.id} className="journal">
                  <NavLink to={`/journals/${item.id}`}>
                    <h2>{item.year}년 {item.numMonth}월 {item.day}일 {item.day_week}</h2>
                    <p>{item.text} </p>
                  </NavLink>
                </div>
              ))}
            </ul>
        </div>
 
  )
}

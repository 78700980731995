import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useParams} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

import OliveStory from "../assets/images/OliveStory.png"
import JoyStory from "../assets/images/JoyStory.png"
import JackieStory from "../assets/images/JackieStory.png"
import EveStory from "../assets/images/EveStory.png"
import KerryStory from "../assets/images/KerryStory.png"
import BenStory from "../assets/images/BenStory.png"

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;

export default function SearchResult() {
  const { id } = useParams()
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState("");

  const [result1, setResult1] = useState("");
  const [result2, setResult2] = useState("");
  const [result3, setResult3] = useState("");
  const [result4, setResult4] = useState("");
  const [result5, setResult5] = useState("");
  const [result6, setResult6] = useState("");

  //user information
  const [vec, setVec] = useState([]);
  const [svec, setSvec] = useState([]);
  const [basicStatusVec, setBasicStatusVec] = useState([]);
  const [level, setLevel] = useState("");

  const handleSearch = async () => {
    if (level<4){
        LowLevelResultFetch();
    }
    else if (level==4){
        MiddleLevelResultFetch();
    }
    else if (level==5 || level==6){
        HighLevelResultFetch();
    }
  }

  const LowLevelResultFetch = async() => {
    console.log("you are level 3")
  }

  const MiddleLevelResultFetch = async() => {
    console.log("you are level 4")
  }

  const HighLevelResultFetch = async() => {
    console.log("you are level 5 or 6")
  }

  const ResultFetch = async() => {
        //array shuffle
        function shuffle(array) {
          let currentIndex = array.length,  randomIndex;
        
          // While there remain elements to shuffle.
          while (currentIndex > 0) {
        
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
              array[randomIndex], array[currentIndex]];
          }
        
          return array;
        }
        
      //crumpt 관련
      var temp_crumps = [];
      if (vec[0]==0 ||vec[2]==0 ||vec[4]==0){
          temp_crumps.push("0")
      }
      if (vec[0]==1 ||vec[2]==1 ||vec[4]==1){
          temp_crumps.push('1')
      }
      if (vec[0]==2 ||vec[2]==2 ||vec[4]==2){
          temp_crumps.push('2')
      }
      if (vec[0]==3 ||vec[2]==3 ||vec[4]==3){
          temp_crumps.push('3')
      }
      if (vec[0]==4 ||vec[2]==4 ||vec[4]==4){
          temp_crumps.push('4')
      }
      if (vec[0]==5 ||vec[2]==5 ||vec[4]==5){
          temp_crumps.push('5')
      }
      if (vec[0]==6 ||vec[2]==6 ||vec[4]==6){
          temp_crumps.push('6')
      }
      if (vec[0]==7 ||vec[2]==7 ||vec[4]==7){
          temp_crumps.push('7')
      }
      if (vec[0]==8 ||vec[2]==8 ||vec[4]==8){
          temp_crumps.push('8')
      }
      if (vec[0]==9 ||vec[2]==9 ||vec[4]==9){
          temp_crumps.push('9')
      }
      //지지
      if (vec[1]==0 ||vec[3]==0 ||vec[5]==0){
          temp_crumps.push('10')
      }
      if (vec[1]==1 ||vec[3]==1 ||vec[5]==1){
          temp_crumps.push('11')
      }
      if (vec[1]==2 ||vec[3]==2 ||vec[5]==2){
          temp_crumps.push('12')
      }
      if (vec[1]==3 ||vec[3]==3 ||vec[5]==3){
          temp_crumps.push('13')
      }
      if (vec[1]==4 ||vec[3]==4 ||vec[5]==4){
          temp_crumps.push('14')
      }
      if (vec[1]==5 ||vec[3]==5 ||vec[5]==5){
          temp_crumps.push('15')
      }
      if (vec[1]==6||vec[3]==6 ||vec[5]==6){
          temp_crumps.push('16')
      }
      if (vec[1]==7 ||vec[3]==7 ||vec[5]==7){
          temp_crumps.push('17')
      }
      if (vec[1]==8 ||vec[3]==8||vec[5]==8){
          temp_crumps.push('18')
      }
      if (vec[1]==9 ||vec[3]==9 ||vec[5]==9){
          temp_crumps.push('19')
      }
      if (vec[1]==10 ||vec[3]==10 ||vec[5]==10){
          temp_crumps.push('20')
      }
      if (vec[1]==11 ||vec[3]==11 ||vec[5]==11){
          temp_crumps.push('21')
      }

      shuffle(temp_crumps);

      if (temp_crumps.length < 6){
          temp_crumps.push('21')
          temp_crumps.push('20')
          temp_crumps.push('19')
      }
        
    //우선 검색어 분류
    const res2 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/searchclassify', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data2 = await res2.json()
        
        console.log(data2.classifyResult.content);

        var temp_var = data2.classifyResult.content;
    
      if (temp_var.includes("일상") ) {

        for (let i = 0; i < temp_crumps.length; i++) {
          temp_crumps[i]= "a" + temp_crumps[i] + "c";
        }

        //서치 결과 1
          var temp_resu1 = [];
          const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({title: id}),
              method: 'POST'
          })
          const data = await res.json()
          console.log(data)
          console.log(data.searchResult)
          temp_resu1.push("올리브");
          temp_resu1.push(data.searchResult.content)
          setResult1(temp_resu1);

        //서치 결과2
        var temp_resu2 = [];
        const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data4 = await res4.json()

        temp_resu2.push("벤");
        temp_resu2.push(data4.searchResult.content)
        setResult2(temp_resu2);    
        
        //서치 결과3
        var temp_resu3 = [];
        const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data5 = await res5.json()

        temp_resu3.push("조이");
        temp_resu3.push(data5.searchResult.content)
        setResult3(temp_resu3);   

        //서치 결과4
        var temp_resu4 = [];
        const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data6 = await res6.json()

        temp_resu4.push("재키");
        temp_resu4.push(data6.searchResult.content)
        setResult4(temp_resu4); 
        
        //서치 결과5
        var temp_resu5 = [];
        const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data7 = await res7.json()

        temp_resu5.push("이브");
        temp_resu5.push(data7.searchResult.content)
        setResult5(temp_resu5); 

        //서치 결과6
        var temp_resu6 = [];
        const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data8 = await res8.json()

        temp_resu6.push("커리");
        temp_resu6.push(data8.searchResult.content)
        setResult6(temp_resu6); 


          setPending(false);
      } 
      else if (temp_var.includes("인간관계") ){
        for (let i = 0; i < temp_crumps.length; i++) {
          temp_crumps[i]= "a" + temp_crumps[i] + "b";
        }

        //서치 결과 1
          var temp_resu1 = [];
          const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({title: id}),
              method: 'POST'
          })
          const data = await res.json()
  
          temp_resu1.push("조이");
          temp_resu1.push(data.searchResult.content)
          setResult1(temp_resu1);

        //서치 결과2
        var temp_resu2 = [];
        const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data4 = await res4.json()

        temp_resu2.push("재키");
        temp_resu2.push(data4.searchResult.content)
        setResult2(temp_resu2);    
        
        //서치 결과3
        var temp_resu3 = [];
        const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data5 = await res5.json()

        temp_resu3.push("올리브");
        temp_resu3.push(data5.searchResult.content)
        setResult3(temp_resu3);   

        //서치 결과4
        var temp_resu4 = [];
        const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data6 = await res6.json()

        temp_resu4.push("벤");
        temp_resu4.push(data6.searchResult.content)
        setResult4(temp_resu4); 
        
        //서치 결과5
        var temp_resu5 = [];
        const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data7 = await res7.json()

        temp_resu5.push("이브");
        temp_resu5.push(data7.searchResult.content)
        setResult5(temp_resu5); 

        //서치 결과6
        var temp_resu6 = [];
        const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data8 = await res8.json()

        temp_resu6.push("커리");
        temp_resu6.push(data8.searchResult.content)
        setResult6(temp_resu6); 


          setPending(false);
      }
      else if (temp_var.includes("일")){
        for (let i = 0; i < temp_crumps.length; i++) {
          temp_crumps[i]= "a" + temp_crumps[i] + "c";
        }

        //서치 결과 1
          var temp_resu1 = [];
          const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({title: id}),
              method: 'POST'
          })
          const data = await res.json()
  
          temp_resu1.push("이브");
          temp_resu1.push(data.searchResult.content)
          setResult1(temp_resu1);

        //서치 결과2
        var temp_resu2 = [];
        const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data4 = await res4.json()

        temp_resu2.push("커리");
        temp_resu2.push(data4.searchResult.content)
        setResult2(temp_resu2);    
        
        //서치 결과3
        var temp_resu3 = [];
        const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data5 = await res5.json()

        temp_resu3.push("조이");
        temp_resu3.push(data5.searchResult.content)
        setResult3(temp_resu3);   

        //서치 결과4
        var temp_resu4 = [];
        const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data6 = await res6.json()

        temp_resu4.push("재키");
        temp_resu4.push(data6.searchResult.content)
        setResult4(temp_resu4); 
        
        //서치 결과5
        var temp_resu5 = [];
        const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data7 = await res7.json()

        temp_resu5.push("올리브");
        temp_resu5.push(data7.searchResult.content)
        setResult5(temp_resu5); 

        //서치 결과6
        var temp_resu6 = [];
        const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search'+`${temp_crumps[0]}`, {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data8 = await res8.json()

        temp_resu6.push("벤");
        temp_resu6.push(data8.searchResult.content)
        setResult6(temp_resu6); 


          setPending(false);
      }
      else {
        //서치 결과 1
        var temp_resu1 = [];
        const res = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({title: id}),
            method: 'POST'
        })
        const data = await res.json()

        temp_resu1.push("이브");
        temp_resu1.push(data.searchResult.content)
        setResult1(temp_resu1);

      //서치 결과2
      var temp_resu2 = [];
      const res4 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({title: id}),
          method: 'POST'
      })
      const data4 = await res4.json()

      temp_resu2.push("커리");
      temp_resu2.push(data4.searchResult.content)
      setResult2(temp_resu2);    
      
      //서치 결과3
      var temp_resu3 = [];
      const res5 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({title: id}),
          method: 'POST'
      })
      const data5 = await res5.json()

      temp_resu3.push("조이");
      temp_resu3.push(data5.searchResult.content)
      setResult3(temp_resu3);   

      //서치 결과4
      var temp_resu4 = [];
      const res6 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({title: id}),
          method: 'POST'
      })
      const data6 = await res6.json()

      temp_resu4.push("재키");
      temp_resu4.push(data6.searchResult.content)
      setResult4(temp_resu4); 
      
      //서치 결과5
      var temp_resu5 = [];
      const res7 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({title: id}),
          method: 'POST'
      })
      const data7 = await res7.json()

      temp_resu5.push("올리브");
      temp_resu5.push(data7.searchResult.content)
      setResult5(temp_resu5); 

      //서치 결과6
      var temp_resu6 = [];
      const res8 = await fetch('https://server-kor-bec38320dbef.herokuapp.com/openai/search', {
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({title: id}),
          method: 'POST'
      })
      const data8 = await res8.json()

      temp_resu6.push("벤");
      temp_resu6.push(data8.searchResult.content)
      setResult6(temp_resu6); 


        setPending(false);
    
      }

  }
  
  useEffect(() => {
    const unsub2 = onSnapshot(doc(db, 'users', auth.currentUser.uid), (doc) => {
        setLevel(doc.data().level);
    });

    handleSearch();
//ResultFetch();
  }, []);

  return (
    <div className="containerBasic">
      <div className="searchQuery">
        <p>{id}</p>
      </div>

      <div>
        {pending? <p>검색 결과를 로딩중입니다. 잠시만 기다려주세요....</p> : null}
        {!pending?
            <div>
                <div className="onecomment">
                    {(result1|| [])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
                    {(result1|| [])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
                    {(result1|| [])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
                    {(result1|| [])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
                    {(result1|| [])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
                    {(result1|| [])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
                    <p>{(result1|| [])[1]}</p>
                </div>
                <div className="onecomment">
                    {(result2||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
                    {(result2||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
                    {(result2||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
                    {(result2||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
                    {(result2||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
                    {(result2||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
                    <p>{(result2||[])[1]}</p>
                </div>
                <div className="onecomment">
                    {(result3||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
                    {(result3||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
                    {(result3||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
                    {(result3||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
                    {(result3||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
                    {(result3||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
                    <p>{(result3||[])[1]}</p>
                </div>
                <div className="onecomment">
                    {(result4||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
                    {(result4||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
                    {(result4||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
                    {(result4||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
                    {(result4||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
                    {(result4||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
                    <p>{(result4||[])[1]}</p>
                </div>
                <div className="onecomment">
                    {(result5||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
                    {(result5||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
                    {(result5||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
                    {(result5||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
                    {(result5||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
                    {(result5||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
                    <p>{(result5||[])[1]}</p>
                </div>
                <div className="onecomment">
                    {(result6||[])[0]== "올리브" ? <img src={OliveStory} alt="olive" /> : null}
                    {(result6||[])[0]== "조이" ? <img src={JoyStory} alt="olive" /> : null}
                    {(result6||[])[0]== "재키" ? <img src={JackieStory} alt="olive" /> : null}
                    {(result6||[])[0]== "이브" ? <img src={EveStory} alt="olive" /> : null}
                    {(result6||[])[0]== "커리" ? <img src={KerryStory} alt="olive" /> : null}
                    {(result6||[])[0]== "벤" ? <img src={BenStory} alt="olive" /> : null}
                    <p>{(result6||[])[1]}</p>
                </div> 
            </div>
            
        : null}
        </div>  
    </div>
  )
}



import React, {useContext, useState, useEffect} from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';


import Prompts from "../components/Prompts"
import Brain from "../assets/images/brain.png"
import SmallNotification from '../components/SmallNotification';
import PastJournals from '../components/PastJournals';
import Bots from '../components/Bots';
import Narration from '../components/Narration';
import Search from '../components/Search';
import Post from './Post';

import {IoIosJournal} from 'react-icons/io';
import {BsJournalMedical, BsFillPencilFill} from 'react-icons/bs';
import {LuSticker} from 'react-icons/lu';
import {TbReportAnalytics} from 'react-icons/tb';

import {FaWindowClose} from 'react-icons/fa';


const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


const user = auth.currentUser;


export default function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [level, setLevel] = useState("");



  useEffect(() => {

        const unsubAuth = onAuthStateChanged(auth, (user) => {
          console.log('user status changed:', user)
          setUser(user);
        })

      //   const unsub = onSnapshot(doc(db, 'users', auth.currentUser.uid), (doc) => {
      //     setName(doc.data().name)
      // });
      }, []);

  if (!auth.currentUser) return <Navigate replace to="/signin" />
  // if (!user) return <Navigate replace to="/signin" />
  


  return (
    <div className="content">
      <div className="left-side">
          <div className="homeImage">
            <img src={Brain} alt="universe" />
          </div>
          

          <Search/>
          
          

          <div className="homeItems">
            <div className="indi" onClick={() => navigate('/journals')}>
              <IoIosJournal className="homeIcon"/>
              <p>일기들</p>
            </div>
            
            <div className="indi" onClick={() => navigate('/post')}>
              <BsFillPencilFill className="homeIcon"/>
              <p>+</p>
            </div>
            
            <div className="indi" onClick={() => navigate('/template')}>
              <BsJournalMedical className="homeIcon"/>
              <p>템플릿</p>
            </div>
            {/* <div className="indi" onClick={() => navigate('/stickers')}>
              <LuSticker className="homeIcon"/>
              <p>스티커</p>
            </div>
            <div className="indi" onClick={() => navigate('/reports')}>
              <TbReportAnalytics className="homeIcon"/>
              <p>보고서</p>
            </div> */}
          </div>

          <PastJournals/>

          <div className="bb">
            <button onClick={()=>navigate('./journals')}>더보기</button>
          </div>
      </div>


      <div className="right-side">
        <div className="to-center">
          <Prompts/>
          <SmallNotification />
          <Bots/>
          <Narration/>
          <Post/>
          
        </div>

      </div>

    </div>

  )
}

import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

const auth = getAuth(app);

const db = getFirestore(app);  


//질문 2 1개 
export default function Register5() {
    const navigate = useNavigate()
    const [question2List, setQuestion2List] = useState([
      "다른 사람들의 도움으로 풍족한 환경을 만들어내곤 해요. ",
      "제도의 허점을 잘 발견해요.",
      "나의 삶을 잘 지켜내는 잡초 같은 생명력을 가지고 있어요.",
      "어디로 갈지 방향을 예측하기 어려운 성향을 지니고 있어요."
])
    
    const [numIndex, setNumIndex] = useState(0);

    const [text, setText] = useState("")
    return (
        <div className="authContainer">
            <p>{question2List[numIndex]}</p>
            <form onSubmit={console.log('**')}>
                <input type="text" placeholder="답변을 입력하세요." value={text} onChange={(e)=>setText(e.target.value)} required/>
                <input type="submit" value="제출" />
            </form>

            <div><button onClick={() => navigate('/register6')}>이동</button></div>
            <div>이미 유니버스 계정이 있으신가요?</div>
            <a href="/signin">로그인</a>
            <div className="stretch"></div>
        </div>
    )
}

import React, {useContext, useState, useEffect} from 'react';
import { Link, Navigate, useNavigate, NavLink } from 'react-router-dom';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc, getDoc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

  import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

  import { app } from '../config/fbConfig';

  import {FaSearch, FaWindowClose} from 'react-icons/fa';
  import {BiSearch} from 'react-icons/bi';

  import axios from 'axios';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colUserMaterialRef = collection(db, 'usersMaterials')

const user = auth.currentUser;

const Search = () => {
  const { IMP } = window;
      //아임포트
      IMP.init("imp30108654");
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState("");
    const [queries, setQueries] = useState([]);
    const [prompt, setPrompt] = useState("");
    const [name, setName] = useState("");
    const [level, setLevel] = useState("");

    const [dailySearchCount, setDailySearchCount] = useState(0);

    const [searchModal, setSearchModal] = useState(false);
    //하트력 충전 모달 ( 인증결제 모달임 )
    const [heartChargeModal, setHeartChargeModal] = useState(false);
    const [excessWarningModal, setExcessWarningModal] = useState(false);


    const handleExcess = () => {
      setExcessWarningModal(!excessWarningModal)
      
  } 
  const handleExcess2 = () => {
      setExcessWarningModal(!excessWarningModal)
      setHeartChargeModal(!heartChargeModal);
  }

    const toggleSearchModal = () => {
      setSearchModal(!searchModal);
  };
  const toggleExcessModal = () => {
    setExcessWarningModal(!excessWarningModal)
}

const toggleHeartModal = () => {
    setHeartChargeModal(!heartChargeModal);
}
    
    const handleSubmit = async (e) => {
        if (dailySearchCount > 5) {
          handleExcess()
        }
        else {
        e.preventDefault();
        if (level>3){
          alert('나에 대한 검색기능은 유니버스 레벨이 3이상이어야 사용할 수 있어요!:)')
          setSearch("");
        }
        else {
          navigate(`/searchresult/${search}`)
        }
      
        }}

      if(searchModal) {
          document.body.classList.add('active-modal')
      } else {
          document.body.classList.remove('active-modal')
      }

      if(excessWarningModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    if(heartChargeModal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }


    const handleQueryClick = ({item}) => {
      console.log(item)
      navigate(`/searchresult/${item}`)
    }

    const handleRecomm = async (e) => {
      e.preventDefault();
      
      if (level>3){
        alert('오늘의 검색어 추천은 유니버스 레벨이 3이상이어야 사용할 수 있어요!:)')
      }
      else {
        toggleSearchModal();
      }
      }

      //200하트력 구매
    const onClickNice200Pay = async() => {
      //이전에 prepare로 가격 확인하는 부분 구현해야됨. 
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "2000",
        name : "하트력 200 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          // 이전 단계에서 구현한 결제정보 사후 검증 API 
          console.log(rsp.imp_uid)

          //axios 앞에 await 붙이는 걸로 돼있지만 일단 안되니까 뻄..
            const res = axios({
              url: "https://server-kor-bec38320dbef.herokuapp.com/payments/complete",
              method: "post",
              headers: { "Content-Type": "application/json" }, 
              data: { imp_uid: rsp.imp_uid, merchant_uid: "..." },
                });
                switch (res.status) {
                  case "vbankIssued":
                    // 가상계좌 발급 시 로직
                    break;
                  case "success":
                    // 결제 성공 시 로직
                    console.log("두둥 결제 성공~!")
                    break;
                }
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //500하트력 구매
    const onClickNice500Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "5000",
        name : "하트력 500 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //1000하트력 구매
    const onClickNice1000Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "10000",
        name : "하트력 1000 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //2000하트력구매
    const onClickNice2000Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "19000",
        name : "하트력 2000 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        buyer_tel : "010-1234-5678",
        buyer_addr : "서울특별시 강남구 삼성동",
        buyer_postcode : "123-456",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }

    //5000하트력 구매
    const onClickNice5000Pay = async() => {
      //노마드코더 예시
      IMP.request_pay({
        pg : "nice_v2.iamport01m",
        pay_method : "card",
        amount : "48000",
        name : "하트력 5000 구매",
        merchant_uid : "뭐써야될지모르겠음",
        buyer_email : "구매자 이메일",
        buyer_name : "구매자 이름",
        buyer_tel : "010-1234-5678",
        buyer_addr : "서울특별시 강남구 삼성동",
        buyer_postcode : "123-456",
        m_redirect_url: "어쩌고저쩌고"
      }, function(rsp){
        // callback
      //rsp.imp_uid 값으로 결제 단건조회 API를 호출하여 결제결과를 판단합니다.
        if (rsp.success) {   
          // axios로 HTTP 요청
          axios({
            url: "{서버의 결제 정보를 받는 endpoint}",
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: {
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid
            }
          }).then((data) => {
            // 서버 결제 API 성공시 로직
//             최종 결제결과 로직처리는 반드시 웹훅을 이용하여 안정적으로 처리해 주셔야 합니다.
// 웹훅 연동을 생략하시는 경우 결제결과를 정상적으로 수신받지 못하는 상황이 발생합니다.
// 이전 단계에서 구현한 결제정보 사후 검증 API 호출
                
          })
        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });
    }
    
    useEffect(() => {

        const unsub = onSnapshot(doc(db, 'usersMaterials', auth.currentUser.uid), (doc) => {
            setQueries(doc.data().searchQueries)
            console.log(queries);      
        });

        const unsub2 = onSnapshot(doc(db, 'users', auth.currentUser.uid), (doc) => {
          setName(doc.data().name);
          setLevel(doc.data().level);
          setDailySearchCount(doc.data().DailySearchCount);
      });

        
      }, []);

    return ( 
        <div>
          <div className="checking">
              <div className="search-wrapper">
                <form>
                <input type="text" name="focus" className="search-box" required value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="당신에 관해 무엇이든 검색해보세요!" />
                  {/* <button className="close-icon" type="reset"></button> */}
                  <BiSearch className="searchIcon close-icon" type="reset" onClick={handleSubmit}/>
                </form>
              </div>
          </div>

            <div>
                <div className="recommsearch" onClick={handleRecomm}>{name}님을 위한 오늘의 추천 검색어</div>
            </div>

            {searchModal && (
                    <div className="modal order">
                        <div onClick={toggleSearchModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>{name}님을 위한 오늘의 추천 검색어</h2>
                            <ul>
                              { queries && queries.map(item =>{
                                return <li>
                                  <div className="query" onClick={()=> handleQueryClick({item})}>{item}</div>
                                </li>
                              })}
                            </ul>
                            
                            <FaWindowClose className="close-modal" onClick={toggleSearchModal}/>
                            
                        </div>
                    </div>
            )}

{excessWarningModal && (
                    <div className="modal order">
                        <div onClick={toggleExcessModal} className="overlay"></div>
                        <div className="modal-content">
                            <h2>하루 사용 가능한 검색 횟수를 초과하였습니다. 30 하트력을 사용하여 검색하시겠어요?</h2>
                            

                            
                            <button className="button" onClick={handleExcess2}>하트력 사용</button>
                            
                            {/* <button className="close-modal" onClick={toggleModal}>
                            CLOSE
                            </button> */}
                            <FaWindowClose className="close-modal" onClick={toggleExcessModal}/>
                        </div>
                    </div>
            )}

{heartChargeModal && (
                  <div className="modal order">
                  <div onClick={toggleHeartModal} className="overlay"></div>
                  <div className="modal-content">
                      <FaWindowClose className="close-modal" onClick={toggleHeartModal}/>
                      <div className="notee">
                        <p>구매하신 하트력은 구매 후 3개월 이내에 사용해야하며, 그 이후 소멸 됩니다.</p>
                        <p>구매하신 하트력은 취소 및 환불이 불가합니다.</p>
                      </div>
                      <div className='parallel' onClick={onClickNice200Pay}>
                        <p>200하트력 구매</p> 
                        <div className='profilebutton'><p>2000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice500Pay}>
                        <p>500하트력 구매</p> 
                        <div className='profilebutton'><p>5000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice1000Pay}>
                        <p>1000하트력 구매</p> 
                        <div className='profilebutton'><p>10000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice2000Pay}>
                        <p>2000하트력 구매</p> 
                        <div className='profilebutton'><p>19000원</p></div>
                      </div>

                      <div className='parallel' onClick={onClickNice5000Pay}>
                        <p>5000하트력 구매</p> 
                        <div className='profilebutton'><p>48000원</p></div>
                      </div>
                  </div>
              </div>
      )}  
        </div>       
     );
}
 
export default Search
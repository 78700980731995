import React, {useState, useEffect} from 'react';
import { BrowserRouter, Link, Route, Routes, Navigate, useNavigate} from 'react-router-dom'

import {
  getFirestore, collection
} from 'firebase/firestore'

import {
  getAuth,
  onAuthStateChanged
} from 'firebase/auth'

import { app } from './config/fbConfig';

// pages
import Home from './pages/Home';
import About from './pages/About';
import Notifications from './pages/Notifications';
import Journals from './pages/Journals';
import JournalDetails from './pages/JournalDetails';
import Post2 from './pages/Post2';
import Template from './pages/Template';
import Stickers from './pages/Stickers';
import Reports from './pages/Reports';
import SignIn from './pages/SignIn';
import Register from './pages/Register';
import Register2 from './pages/Register2';
import Profile from './pages/Profile';
import BillingProfile from './pages/BillingProfile'
import SearchResult from './pages/SearchResult';
import DailyLog from './pages/DailyLog';
import MoodCheckIn from './pages/MoodCheckIn';
import Services from './pages/Services';

import {FaHome} from 'react-icons/fa';
import {MdPerson, MdNotifications} from 'react-icons/md';
import {CgProfile} from 'react-icons/cg';
import Register3 from './pages/Register3';
import Register4 from './pages/Register4';
import Register5 from './pages/Register5';
import Register6 from './pages/Register6';



const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')


function App() {
  const [user, setUser] = useState(null);

    useEffect(() => {

        const unsubAuth = onAuthStateChanged(auth, (user) => {
          console.log('user status changed:', user)
          setUser(user);
        })
      }, []);

  return (
    <BrowserRouter>
      <div className="App"> 
      
      {user? <nav>
          <h1>Universe</h1>
            <Link to="/"><FaHome className="navIcon" /></Link>
            <Link to="/notifications">
              <MdNotifications className="navIcon"/>
            </Link>
            <Link to="/profile">
              <CgProfile className="navIcon"/>
            </Link>
        </nav> : null
        }

        {!user? <nav>
          <h1>Universe</h1>
            <Link to="/signin">로그인</Link>
            <Link to="/register">회원가입</Link>
        </nav> : null
        } 

        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/searchresult/:id" element={<SearchResult/>}/>
          <Route path="/signin" element={<SignIn/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/register2" element={<Register2/>} />
          <Route path="/register3" element={<Register3/>} />
          <Route path="/register4" element={<Register4/>} />
          <Route path="/register5" element={<Register5/>} />
          <Route path="/register6" element={<Register6/>} />
          <Route path="/notifications" element={<Notifications/>}/>
          <Route path="/profile" element={<Profile />}/>
          <Route path="/services" element={<Services />}/>
          <Route path="/billingprofile" element={<BillingProfile/>}/> 
          <Route path="/journals" element={<Journals/>}/>
          <Route path="/journals/:id/*" element={<JournalDetails/>} />
          <Route path="/post" element={<Post2/>}/>
          <Route path="/template" element={<Template/>}/>
          <Route path="/stickers" element={<Stickers/>}/>
          <Route path="/dailylog" element={<DailyLog/>}/>
          <Route path="/moodcheckin" element={<MoodCheckIn/>}/>
          <Route path="/reports" element={<Reports/>}/>
          <Route path="/redirect" element={<Navigate to="/about" />} />
        </Routes>
      </div>
      <div>
        <footer>
            <div>
              <p>&copy; Olive Philosophy 2023. All Rights Reserved.</p>
            </div>
            <div className="cominfo">
              <p>주소 서울시 종로구 창경궁로 16길 70, 406호(효제동)</p>
              <p>사업자등록번호 325-38-01110</p>
              <p>상호명 올리브 필로소피 | 대표자명 오유라</p>
              <p>Tel) 02-6013-6180</p>
            </div>
          </footer>
      </div>
    </BrowserRouter>
  )
}

export default App
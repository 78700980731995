import React, {useContext, useState} from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { app } from '../config/fbConfig';
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

const auth = getAuth(app);

const db = getFirestore(app);  


//질문 2 1개 
export default function Register4() {
    const navigate = useNavigate()
    const [stickerList, setStickerList] = useState([
      "기분이 좋아지고 싶을 때 듣는 특별한 노래가 있나요?",
      "편의점에서 주로 어떤 것들을 사나요?",
      "혼자서 잘 만들 수 있는 음식이 있나요?",
      "가장 좋아하는 책을 알려주세요.",
      "어떤 날씨를 가장 좋아하나요?"
])
    
    const [numIndex, setNumIndex] = useState(0);

    const [text, setText] = useState("")
    return (
        <div className="authContainer">
            <p>{stickerList[numIndex]}</p>
            <form onSubmit={console.log('**')}>
                <input type="text" placeholder="답변을 입력하세요." value={text} onChange={(e)=>setText(e.target.value)} required/>
                <input type="submit" value="제출" />
            </form>

            <div><button onClick={() => navigate('/register5')}>이동</button></div>
            <div>이미 유니버스 계정이 있으신가요?</div>
            <a href="/signin">로그인</a>
            <div className="stretch"></div>
        </div>
    )
}

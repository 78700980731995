import React, {useContext, useState, useEffect} from 'react';
import { BrowserRouter, NavLink, Link, Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {
    getFirestore, collection, onSnapshot,
    addDoc, deleteDoc, doc,
    query, where,
    orderBy, limit, serverTimestamp,
    updateDoc,
    setDoc, Timestamp
  } from 'firebase/firestore'

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged
  } from 'firebase/auth'

import { app } from '../config/fbConfig';

const auth = getAuth(app);

const db = getFirestore(app);  

const colUserRef = collection(db, 'users')
const colPostRef = collection(db, 'posts')
const colNotiRef = collection(db, 'notifications')


const user = auth.currentUser;

export default function Notifications() {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  const handleNotiItem = (e) => {
    e.preventDefault();
    console.log("noti clicked")
  }

  useEffect(() => {
    // queries
    const q = query(colNotiRef, where("uid", "==", auth.currentUser.uid), orderBy('createdAt', "desc"))

    // realtime collection data
    onSnapshot(q, (snapshot) => {
    let notifications = []
    snapshot.docs.forEach(doc => {
      notifications.push({ ...doc.data(), id: doc.id })
    })
    console.log(notifications);
    setNotifications(notifications);
    })
  }, []);  
  return (
    <div className="containerBasic">
            <ul>
              {notifications && notifications.map(item => (
                <div key={item.id} className="notiIndi" onClick={handleNotiItem}>
                  <p>{item.content}</p>
                </div>
              ))}
            </ul>
        </div>
  )
}
